import Footer from "../../../components/layout/footer"
import Navbar from "../../../components/layout/header"
import { ROUTES } from "../../../constants/routes"

import stckBackground from "../../../assets/images/backgroudImages/PatentsGrantedByUSPTO.jpg"

import ArticleStructure from "../../../components/articleStructure"

export default function index() {
  return (
    <>
      <Navbar></Navbar>

      <ArticleStructure
        location="ROYAL OAK, Michigan"
        date="April 21, 2020"
        title="Immunis.AI Announces Patents Granted by USPTO, Strengthening the Company’s Intellectual Property Position in Novel Methods and Technologies for Cancer Detection and Prognosis"
        initialText=""
        previousNews={ROUTES.article9}
        aboutEnabled={true} netxNews={ROUTES.article7}
      >
        <div className="mt-3 w-100">
          <img className="w-100" src={stckBackground} />
          <p className="mt-2 text-gray text-center">
          United States Patent and Trademark Office (USPTO) headquarters. The image was taken from{" "}
            <a
              className="underlined"
              href="https://big-ip.com/blog/proud-to-support-the-uspto-patent-pro-bono-program/."
            >
              https://big-ip.com/blog/proud-to-support-the-uspto-patent-pro-bono-program/.
            </a>
          </p>
        </div>
        <div className="pt-20">
          <p className="text">
          Immunis.AI today announced that it has received an additional patent from the United State Patent and Trademark Office (USPTO), which protects the technology and biomarkers related to assessing the progression of cancer. This new patent, <span className="text-yellow">Patent No. 10494675</span>, compliments the technology protected in <span className="text-yellow">Patent No. 10626464</span> granted on December 3, 2019, directed to identifying cancers such as prostate and head and neck.
          </p>
        </div>
        
        <div className="pt-4">
          <p className="text">
          These patents describe aspects of “subtractive method,” directed to detection, diagnosis and prognosis of prostate cancer, as well as head & neck cancer, by determining the difference between the levels of gene expression of certain sets of markers from the subject’s phagocytic and non-phagocytic cells. Taken together, these patents, along with other intellectual property of Immunis.AI, demonstrate that the subtractive method is patentable and has been patented by Immunis.AI.
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
          Immunis.AI was one of the first companies to patent the subtractive method, positioning us to offer patients minimally-invasive cancer detection when there is the best opportunity for cure. We hold one of the most robust patent portfolios in novel detection methods with the potential to overcome a limitation of circulating tumor cells (CTCs) and cell free DNA (cfDNA). The addition of these recent patents further strengthens our intellectual property position and underscores our commitment to innovation in advancing diagnostic development and delivery of high performing tests.
            <p className="attribution text">
            Christopher Thibodeau, President and CEO, Immunis.AI
            </p>
          </p>
        </div>
        
      </ArticleStructure>

      <Footer></Footer>
    </>
  )
}
