import { useEffect, useState } from "react"
import { Field, Form, Formik } from "formik"
import { Link } from "react-router-dom"
import AOS from "aos"
import jsonp from "jsonp"
import * as Yup from "yup";

import DiagnosisCard from "../../components/cards/diagnosisArray"
import Footer from "../../components/layout/footer"
import Navbar from "../../components/layout/header"
import { ROUTES } from "../../constants/routes"
import {MixpanelTracking} from "../../services/Mixpanel"

import empowering from "../../assets/images/iconsVector/icon_empowering_new.svg"
import impactfull from "../../assets/images/iconsVector/icon_impact_new.svg"
import treatmentPlan from "../../assets/images/png/treatment_plan.png"
import safelyProtocol from "../../assets/images/png/safely_protocol.png"
import bloodBasedAssay from "../../assets/images/png/blood_based_assay.png"
import patientPlan from "../../assets/images/png/patient_plan.png"
import urologicalsCancer from "../../assets/images/png/urological_cancer.png"
import videoUrl from "../../assets/videos/rna_molecule.mp4"
import waringError from "../../assets/images/iconsVector/warningError.svg"

const initialValue = {
  FNAME: "",
  LNAME: "",
  EMAIL: "",
}

const ValidationSchema = Yup.object().shape({
  FNAME: Yup.string().required("First name is required"),
  LNAME: Yup.string().required("Last name is required"),
  EMAIL: Yup.string().required("Email is required")
});

const ImmunisLandingPageComponent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
      MixpanelTracking.getInstance().HomePage();

    AOS.init({
      once: true,
    });
    AOS.refresh();
  }, []);

  const handleFormSubmit = (values: any, actions: any) => {
    const url = "https://immunis.us21.list-manage.com/subscribe/post-json?u=2b572b065e617a9e9f9b1f944&amp;id=970db65c77&amp;f_id=0025dfe6f0";
    setIsSubmitting(true);

    jsonp(`${url}&EMAIL=${values.EMAIL}&FNAME=${values.FNAME}&LNAME=${values.LNAME}&INQUIRY=${values.INQUIRY}&TITLE=${values.TITLE}&ORG=${values.ORG}&PHONE=${values.PHONE}`, { param: 'c' }, (_:any, data:any) => {
      const { msg, result } = data;

      if(result !== 'error') {
        actions.resetForm({values: ''});
        MixpanelTracking.getInstance().SubscribedToNewsletter(values.EMAIL);
      }

      setIsSubmitting(false);
      alert(msg);
    });
  }
  
  return (
    <>
    <Navbar />
      <div className="relative home-video-wrapper">
        <video autoPlay={true} muted loop>
          <source src={videoUrl} type="video/mp4" />
        </video>
        <div className="revolutionary-container">
          {/* <div className="layer"></div> */}
          <div className="grid lg:grid-cols-2 gap-1 pb-5 mt-32">
            <div className="revolutionaryDiv" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="2000">
              <span className="text-white heading">Revolutionary </span>
              <div>
                <span className="heading text-transparent bg-clip-text bg-gradient-to-r from-[#378091] to-[#F2E854] ">
                  Liquid biopsy solution
                </span>
              </div>

              <p className="subHeading">
                Real-time active surveillance and early detection assays
              </p>

              <div data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                <Link
                  className="mt-10 solutionLearButton"
                  to={ROUTES.immunisAISolution}
                  onClick={() => MixpanelTracking.getInstance().ClickEvent("Learn more Button")}
                >
                  <span className="buttonText">Learn more</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="text-white bg-black cellRoots-wrapper" >
        <div className="container block grid-cols-2 gap-0 sm:grid">
          <div className="cellRootsBackground desktop-img">
            {/* <div className='layer'></div> */}
          </div>
          <div className="flex flex-col items-end gap-[20px] py-[120px]"  data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
            <p className="">
              <b>Immunis.AI</b> is a privately held company committed to making
              a global impact by empowering patients and their physicians with
              actionable information for disease management.
            </p>
            <p className="">
              Our patented platform leverages the body’s own immune defense
              system, state of the art analytical methods, and AI / machine
              learning to provide unique real-time insights into a patient’s
              disease.
            </p>
            <p className="">
              Our vision is to leverage our validated platform to deliver a
              suite of non-invasive, blood-based, assays that can have broad use
              in active surveillance and early detection.
            </p>
            <p className="">
              Our mission is to provide tools for physicians and patients that
              can be used to more accurately make critical decisions on
              treatment plans for patients in the most comfortable and cost
              efficient manner.
            </p>
          </div>
          <div className="cellRootsBackground mobile-img">
            {/* <div className='layer'></div> */}
          </div>
        </div>
      </div>

      <div className="clinical-paradigm-section" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
        <div className="surveillance-tests-text mb-10">Active Surveillance Tests</div>
        <div className="clinical-paradigm-cards grid lg:grid-cols-3 lg:gap-5 md:grid-cols-2 md:gap-4 sm:grid-cols-1 sm:gap-1 justify-center">
          <div className="clinical-paradigm-card flex items-center md:block" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
            <div className="card-image-container flex justify-center mb-0 mb-md-3">
              <img className="card-image img-fluid rounded-circle" src={treatmentPlan} />
            </div>
            <p className="card-content ml-3 ml-md-0">Making Active Surveillance a BETTER component of the treatment plan</p>
          </div>

          <div className="clinical-paradigm-card flex items-center md:block" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
            <div className="card-image-container flex justify-center mb-0 mb-md-3">
              <img className="card-image img-fluid rounded-circle" src={safelyProtocol} />
            </div>
            <p className="card-content ml-3 ml-md-0">SAFELY remain or begin on an Active Surveillance protocol</p>
          </div>

          <div className="clinical-paradigm-card flex items-center md:block" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
            <div className="card-image-container flex justify-center mb-0 mb-md-3">
              <img className="card-image img-fluid rounded-circle" src={bloodBasedAssay} />
            </div>
            <p className="card-content ml-3 ml-md-0">First non-invasive blood-based assay for Active Surveillance validated on Thousands of patients</p>
          </div>

          <div className="clinical-paradigm-card flex items-center md:block" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
            <div className="card-image-container flex justify-center mb-0 mb-md-3">
              <img className="card-image img-fluid rounded-circle" src={patientPlan} />
            </div>
            <p className="card-content ml-3 ml-md-0">Will improve physician and patient adherence to established Active Surveillance Guidelines</p>
          </div>

          <div className="clinical-paradigm-card flex items-center md:block" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
            <div className="card-image-container flex justify-center mb-0 mb-md-3">
              <img className="card-image img-fluid rounded-circle" src={urologicalsCancer} />
            </div>
            <p className="card-content ml-3 ml-md-0">Initial test is for Prostate Cancer with future tests in other urological cancers</p>
          </div>
        </div>

        <div className="divider"></div>

        <div className="current-paradigm-section" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
          <h4 className="current-paradigm-heading">Compared to the current clinical paradigm</h4>

          <div className="flex items-center">
            <div className="list-dot"></div>
            <div className="list-item-content">MORE aggressive cancer will be found</div>
          </div>

          <div className="flex items-center mt-3 pt-1">
            <div className="list-dot"></div>
            <div className="list-item-content">FEWER indolent cancers will be treated and will remain on Active Surveillance</div>
          </div>
        </div>
      </div>

      <div className="container sm:pt-[160px] sm:pb-[120px] pt-[120px] pb-[90px]" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
        <div className="flex justify-start sm:mb-[60px] mb-[45px]">
          <span className="DiagnosticArraySpan" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
            Immunis.AI diagnostic assays are
          </span>
        </div>
        <div className="md:grid md:grid-cols-3 gap-0" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
          <DiagnosisCard
            heading="Impactful"
            img={impactfull}
            content="We will improve the human condition globally by combining the power of the 
            immune system and our proprietary platform, to deliver actionable medical information."
          ></DiagnosisCard>
          <DiagnosisCard
            heading="Empowering"
            img={empowering}
            content="We will empower patients with deeper insights into their current health and importantly, 
            the opportunity for making critical decisions to move to more agressive treatment, 
            when disease burden is still low and there is the greatest chance for cure."
          ></DiagnosisCard>
          <DiagnosisCard
            heading="Life-changing"
            img={impactfull}
            content="We will deliver novel tests on our immunogenomics platform which will save lives, 
            enrich quality of life and provide peace of mind to patients and their loved ones."
          ></DiagnosisCard>
        </div>
      </div>

      <div className="relative bg-solution solutionBackground" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
        <div className="layer"></div>
        <div className="container grid md:grid-cols-2 gap-1 sm:py-[160px] py-[120px]">
          <div className="solution">
            <span className="solutionHeading">SOLUTION</span>
            {/* card-1 */}
            <div className="mt-10 solutionDetails" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
              <span className="heading">
                Real-time Earlier Signal Detection
              </span>
              <p className="content">
                Our immune system rapidly reacts to threats, triggering an
                internal amplification structure that causes changes to RNA
                expression levels within immune cells.
              </p>
            </div>
            {/* card-2 */}
            <div className="mt-10 solutionDetails" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
              <span className="heading">Real-Time Surveillance</span>
              <p className="content">
                Real-time surveillance of gene expression of monocytes and
                lymphocytes obtained from a patient enables the detection of
                immune-response signal changes that are caused by (i) intrinsic
                inter-individual variability, e.g. gender, genetic/ethnic
                background (ii) the body’s response to foreign threats such as
                cancer.
              </p>
            </div>
            {/* card-3 */}
            <div className="mt-10 solutionDetails" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
              <span className="heading">Generative AI Machine Learning</span>
              <p className="content">
                Applying a combination of bioinformatics, machine learning and
                biostatistical approaches, we have developed sophisticated
                generative AI algorithms to address and predict complex
                biological problems associated with high dimensionality and
                population, tumor, and outcome heterogeneity with complex or
                structured endpoints.
              </p>
            </div>

            <div className="">
              <Link
                className="mt-10 solutionLearButton"
                to={ROUTES.immunisAISolution}
                data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000"
              >
                <span className="buttonText">Learn more</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="relative"></div>
      </div>

      <div className="emailDiv">
        <div className="container">
          <div className="ConnectWithUs" id="connect-with-us">
            <div className="flex justify-center"  data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
              <span className="content text-transparent bg-clip-text bg-gradient-to-r from-[#378091] to-[#F2E854] ">
                Get connected and receive the latest news about Immunis.AI
              </span>
            </div>
            <div className="lg:px-5 px-3 mt-md-5 mt-3"  data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
              <div className="emailFormBg flex justify-center align-items-center flex-row">
                <Formik
                  initialValues={initialValue}
                  onSubmit={(values, actions) => handleFormSubmit(values, actions)}
                  validationSchema={ValidationSchema}
                >
                  {({ errors }) => (
                    <Form>
                      <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-3">
                        <div className="form-group mb-3">
                          <label className="formLabel" htmlFor="FNAME">
                            First name<span className="requiredAstricks">*</span>
                          </label>
                          <Field
                            type="text"
                            id="FNAME"
                            name="FNAME"
                            placeholder="Enter First name"
                            className="text-white form-control formFeild"
                          />
                          {errors.FNAME ? (
                            <div className="flex mb-2 mt-1 formError">
                              <img className="warning-img" src={waringError} />
                              <span className="ml-2">{errors.FNAME}</span>
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group mb-3">
                          <label className="formLabel" htmlFor="LNAME">
                            Last name <span className="requiredAstricks">*</span>
                          </label>
                          <Field
                            type="text"
                            id="LNAME"
                            name="LNAME"
                            placeholder="Enter Last name"
                            className="text-white form-control formFeild"
                          />
                          {errors.LNAME ? (
                            <div className="flex mb-2 mt-1 formError">
                              <img className="warning-img" src={waringError} />
                              <span className="ml-2">{errors.LNAME}</span>
                            </div>
                          ) : null}
                        </div>
                      </div>  
                      <div className="form-group mb-3">
                        <label className="formLabel" htmlFor="EMAIL">
                          Email address<span className="requiredAstricks">*</span>
                        </label>
                        <Field
                          type="text"
                          id="EMAIL"
                          name="EMAIL"
                          placeholder="Enter email Adress"
                          className="text-white form-control formFeild"
                        />
                        {errors.EMAIL ? (
                          <div className="flex mb-2 mt-1 formError">
                            <img className="warning-img" src={waringError} />
                            <span className="ml-2">{errors.EMAIL}</span>
                          </div>
                        ) : null}
                      </div>
                      
                      <div className="flex justify-center align-items-center">
                        <button
                          disabled={!errors || isSubmitting}
                          type="submit"
                          className="mb-4 mt-4 contactUsButton"
                        >
                          <span className="buttonText">Send</span>
                        </button>
                      </div>  
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  )
}

export default ImmunisLandingPageComponent
