import Footer from "../../components/layout/footer"
import Navbar from "../../components/layout/header"

// import bladderCancerStats from "../../assets/images/theme/BladderCancerStats.svg"
// import kidneyCancerStats from "../../assets/images/theme/kidneyCancerStats.svg"
// import prostateCancerStats from "../../assets/images/theme/prostateCancerStats.svg"
import barChartImage from "../../assets/images/png/Chart.webp"

const LiquidBiopsyPipeline = () => {
  const survilenceArray = [
    { nnum: "01", heading: "Urethral Cancer", details: "" },
    { nnum: "02", heading: "Testicular Cancer", details: "" },
    { nnum: "03", heading: "Pancreatic Cancer", details: "" },
    {
      nnum: "04",
      heading: "Thyroid cancer",
      details:
        "Papillary thyroid cancer, may be suitable for active surveillance, especially in cases where the tumor is small and slow-growing",
    },
    {
      nnum: "05",
      heading: "Breast cancer",
      details:
        "Some forms of breast cancer, such as ductal carcinoma in situ (DCIS), may be suitable for active surveillance, although this approach is controversial",
    },
    {
      nnum: "06",
      heading: "Lymphoma",
      details:
        "Certain types of low-grade lymphomas may be observed with active surveillance instead of immediately starting treatment",
    },
    {
      nnum: "07",
      heading: "Melanoma",
      details:
        "For very early-stage melanomas, active surveillance may be an option in some cases. (Intraocular melanoma)",
    },
    {
      nnum: "08",
      heading: "Colorectal cancer",
      details:
        "In some cases of early-stage rectal cancer, active surveillance may be considered as an alternative to immediate surgery",
    },
    {
      nnum: "09",
      heading: "Non-melanoma skin cancers",
      details:
        "Some low-risk basal cell carcinomas and squamous cell carcinomas may be observed with active surveillance instead of immediately starting treatment",
    },
  ]

  return (
    <>
      <Navbar></Navbar>
      <div className="pipelineContainer">
        <div className="container py-5 mt-32">
          <div className="flex justify-start">
            <span className="pipelineHeading text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
              PIPELINE
            </span>
          </div>
          <p className="pipelineSubHeading my-3">
            Initially, Immunis.AI will offer a suite of assays for Urologists.
          </p>
          <p className="pipelineSubHeading my-3">
            The strategic focus of offering high value assays within the urology channel will maximize operating leverage, allow for decreased time to peak adoption, and reduce costs of the sales funnel.
          </p>

          <div className="mainDiv-1">
            {/* <div className="subDiv-1">
              <div className="p-sm-5 p-2 subDiv-2">
                <div className="grid grid-cols-2 gap-1">
                  <div className="bg-transparent"></div>
                  <div className="flex justify-around p-2 bg-transparent graphXAxisDiv">
                    <div>
                      <p className="graphXaxis">Signature Discovery</p>
                    </div>
                    <div>
                      <p className="graphXaxis">Validation</p>
                    </div>
                    <div>
                      <p className="graphXaxis">Commercialization</p>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-3">
                  <div className="graphYAxisDiv yAxisStatsname">
                    <div className="">
                      <p className="p-5 yAxisDetail">
                        Prostate Cancer – Active Surveillance
                      </p>
                      <p className="p-5 yAxisDetail">
                        Kidney Cancer – Active Surveillance
                      </p>
                      <p className="p-5 yAxisDetail">
                        Bladder Cancer – Active Surveillance
                      </p>
                    </div>
                  </div>

                  <div className="statsImage ">
                    <img className="py-5" src={prostateCancerStats}></img>
                    <img className="py-5" src={kidneyCancerStats}></img>
                    <img className="py-5" src={bladderCancerStats}></img>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="chart-image">
              <img src={barChartImage} className="img-fluid w-100" />
            </div>
          </div>

          <div className="py-5">
            <span className="additiionalServiceText">
              Additional Active Surveillance Opportunities
            </span>
            <div className="grid lg:grid-cols-3 lg:gap-3 md:grid-cols-2 md:gap-2 sm:grid-cols-1 sm:gap-1">
              {survilenceArray.map((data: any, index) => (
                <div key={index}>
                  <div className="flex mt-5">
                    <div>
                      <span>
                        <span className="survilenceberNumber text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
                          {data?.nnum}
                        </span>
                      </span>
                    </div>
                    <div className="flex justify-center align-items-center p-2 borderClass align-items-xxl-center">
                      <p className="survilenceHeading ps-2">{data?.heading}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>  
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

export default LiquidBiopsyPipeline
