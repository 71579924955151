import { useState } from "react"

import closeIcon from "../../assets/images/iconsVector/closeICon.svg"
import dowmArrow from "../../assets/images/iconsVector/downVector.svg"

interface leaderShipCard {
  image: string
  name: string
  designation: string
  details: string
  iconEnable?: boolean
  footer?: { img: string }
}
const LeadershipCard = (props: leaderShipCard) => {
  const [isOpen, setIsOpen] = useState(false)

  const openModel = (data: leaderShipCard) => {
    console.log(data)
    setIsOpen(true)
    // navigate(ROUTES.openModal)
  }

  const { footer, image } = props
  const data = props
  const values = Object.values(image)

  console.log(footer)
  // const valueFooter = Object.values(footer)
  // console.log(valueFooter)

  return (
    <>
      {isOpen && (
        <>
          <div>
            {isOpen && (
              <>
                <div className="fixed inset-0 z-50 flex items-center justify-center popUpMainBackGround">
                  <div className="modal-overlay modelHeight">
                    <div className="p-2 popUpModal">
                      <div className="flex justify-end">
                        <button
                          className="p-3"
                          onClick={() => {
                            setIsOpen(false)
                          }}
                        >
                          <img src={closeIcon}></img>
                        </button>
                      </div>
                      <div className="flex justify-center">
                        <div className="imageDiv">
                          <img className="rounded-circle" src={values[0]} />
                        </div>
                      </div>
                      <div className="leaderDetailsPopUP">
                        <div className="nameContent">
                          <div className="flex justify-center">
                            <span className="py-3 popUpName">{data?.name}</span>
                          </div>

                          <p className="pb-2 popUpDesignation">
                            {data?.designation}
                          </p>
                        </div>

                        <div
                          className="leaderDetails scrollbar-container"
                          dangerouslySetInnerHTML={{ __html: data?.details }}
                        >
                          {/* <p className='leaderDetails'>{data?.details}</p> */}
                        </div>
                      </div>

                      <div className="w-full footerAdjust">
                        <img className="w-full" src={footer?.img} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}

      <div className="p-4 cardContainer">
        <div className="flex justify-center">
          <div className="imageDiv">
            <img className="rounded-circle" src={values[0]} />
          </div>
        </div>
        <div className="nameContent">
          <div className="flex justify-center">
            <span className="nameHeading">{data?.name}</span>
          </div>

          <p className="leaderDesignation">{data?.designation}</p>
        </div>

        <div className="leaderContent">
          <p
            className="nopopUPcardDetails"
            dangerouslySetInnerHTML={{
              __html:
                data?.details.length > 150
                  ? data?.details.substring(0, 140) + "..."
                  : data?.details,
            }}
          >
            {/* {data?.details.length > 150 ? data?.details.substring(0, 140) + '...' : data?.details} */}
          </p>
        </div>
        {data?.iconEnable ? (
          <div className="flex justify-center py-3">
            <img
              className="cursor-pointer hover:"
              onClick={() => openModel(data)}
              src={dowmArrow}
            ></img>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
export default LeadershipCard
