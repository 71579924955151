import { useState, useEffect } from "react"
import { useNavigate, Link, useLocation } from "react-router-dom"

import { ROUTES } from "../../../constants/routes"
import { MixpanelTracking } from "../../../services/Mixpanel"
import NavLink from "../../Common/NavLink/component"

import ImmunisLogo from "../../../assets/images/Logo.svg"
import CancelIcon from "../../../assets/images/Icons/cancel.svg";
import HamburgerIcon from "../../../assets/images/Icons/hamburger.svg";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [blurBgHeader, setBlurBgHeader] = useState(false)
  const [currentPath, setCurrentPath] = useState("#")
  useEffect(() => {
    setCurrentPath(location.pathname)
  }, []);

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", function () {
      const ScrollY = window.scrollY;

      if (blurBgHeader === false && ScrollY > 100) {
        setBlurBgHeader(true);
      } else if (blurBgHeader === true && ScrollY < 100) {
        setBlurBgHeader(false);
      }
    });
  }

  return (
    <header className={`header ${blurBgHeader ? "blurred" : ""}`}>
      {/* desktop design */}
      <div className="desktop-menu">
        <img className="header-logo hover:cursor-pointer" onClick={()=> navigate(ROUTES.home)} src={ImmunisLogo} alt="ImmunisAI logo" />
        <nav className="navbar">
          <NavLink path={ROUTES.about} text="About" onClick={() => { MixpanelTracking.getInstance().VisitSection("About", "header") }} dropdown>
            <Link
              to={ROUTES.about}
            >
              Who we are
            </Link>
            <Link
              to={ROUTES.leadershipTeam}
            >
              Leadership team
            </Link>
            <Link
              to={ROUTES.clinicalAdvisory}
            >
              Clinical advisory board
            </Link>
            <Link
              to={ROUTES.boardsOfDirectors}
            >
              Board of Directors
            </Link>
          </NavLink>
          <NavLink path={ROUTES.medicalNeed}
           text="The Medical Need" onClick={() => { MixpanelTracking.getInstance().VisitSection("The Medical Need", "header") }} />
          <NavLink path={ROUTES.immunisAISolution}
           text="Immunis.AI Solution" onClick={() => { MixpanelTracking.getInstance().VisitSection("Immunis.AI Solution", "header") }} />
          <NavLink path={ROUTES.liquidBiopsy}
           text="Liquid Biopsy Pipeline" onClick={() => { MixpanelTracking.getInstance().VisitSection("Liquid Biopsy Pipeline", "header") }} />
          <NavLink path={ROUTES.news} text="News" onClick={() => { MixpanelTracking.getInstance().VisitSection("News", "header") }} />
          <NavLink path={ROUTES.contactUs}
           text="Contact" onClick={() => { MixpanelTracking.getInstance().VisitSection("Contact", "header") }} />
        </nav>
      </div>

      {/* mobile design */}
      <div className="mobile-menu">
        <img className="header-logo" src={ImmunisLogo} alt="ImmunisAI logo" />
        <button
          className="btn simple"
          onClick={() => setShowMobileMenu(true)}
        >
          <img src={HamburgerIcon} alt="Menu icon" />
        </button>
        <div className={`${showMobileMenu ? "d-flex" : "d-none"} mobile-menu-wrapper`}>
          <div className="header-menu">
            <img className="header-logo hover:cursor-pointer" onClick={()=> navigate(ROUTES.home)} src={ImmunisLogo} alt="ImmunisAI logo" />
            <button
              className="btn simple"
              onClick={() => setShowMobileMenu(false)}
            >
              <img src={CancelIcon} alt="Menu icon" />
            </button>
          </div>
          <nav className="nav-menu">
            <NavLink path={ROUTES.about} text="About" onClick={() => { MixpanelTracking.getInstance().VisitSection("About", "header") }} dropdown>
              <Link
                to={ROUTES.about}
              >
                Who we are
              </Link>
              <Link
                to={ROUTES.leadershipTeam}
              >
                Leadership team
              </Link>
              <Link
                to={ROUTES.clinicalAdvisory}
              >
                Clinical advisory board
              </Link>
              <Link
                to={ROUTES.boardsOfDirectors}
              >
                Board of Directors
              </Link>
            </NavLink>
            <Link className={`nav-link ${currentPath.includes(ROUTES.medicalNeed) ? "gradient-text" : ""}`} to={ROUTES.medicalNeed} onClick={() => { MixpanelTracking.getInstance().VisitSection("The Medical Need", "header") }}>The Medical Need</Link>
            <Link className={`nav-link ${currentPath.includes(ROUTES.immunisAISolution) ? "gradient-text" : ""}`} to={ROUTES.immunisAISolution} onClick={() => { MixpanelTracking.getInstance().VisitSection("Immunis.AI Solution", "header") }}>Immunis.AI Solution</Link>
            <Link className={`nav-link ${currentPath.includes(ROUTES.liquidBiopsy) ? "gradient-text" : ""}`} to={ROUTES.liquidBiopsy} onClick={() => { MixpanelTracking.getInstance().VisitSection("Liquid Biopsy Pipeline", "header") }}>Liquid Biopsy Pipeline</Link>
            <Link className={`nav-link ${currentPath.includes(ROUTES.news) ? "gradient-text" : ""}`} to={ROUTES.news} onClick={() => { MixpanelTracking.getInstance().VisitSection("News", "header") }}>News</Link>
            <Link className={`nav-link ${currentPath.includes(ROUTES.contactUs) ? "gradient-text" : ""}`} to={ROUTES.contactUs} onClick={() => { MixpanelTracking.getInstance().VisitSection("Contact", "header") }}>Contact</Link>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Navbar
