import Footer from "../../../components/layout/footer"
import Navbar from "../../../components/layout/header"
import { ROUTES } from "../../../constants/routes"

import stckBackground from "../../../assets/images/backgroudImages/ParticipationInActiveSurveillancePatientsInternational.jpg"

import BrunoBarrey from "../../../assets/images/backgroudImages/BrunoBarrey.png"
import DanielSpratt from "../../../assets/images/backgroudImages/DanielSpratt.png"
import TimShowalter from "../../../assets/images/backgroudImages/TimShowalter.png"
import DrWojno from "../../../assets/images/backgroudImages/DrWojno.png"

import ArticleStructure from "../../../components/articleStructure"

export default function index() {
  return (
    <>
      <Navbar></Navbar>

      <ArticleStructure
        location=""
        date="March 28, 2024"
        title="Participation In Active Surveillance Patients International (ASPI)"
        initialText=""
        previousNews={ROUTES.article10}
        netxNews={ROUTES.article11}
        aboutEnabled={false}
      >
        <div className="mt-3 w-100">
          <img className="w-100" src={stckBackground} />
          <p className="mt-2 text-gray text-center">
            The image was taken from{" "}
            <a className="underlined" href="https://aspatients.org/">
              https://aspatients.org/
            </a>
          </p>
        </div>
        <div className="mt-20">
          <p className="text">
            Dr. Kirk Wojno, CMO, Immunis.Ai participated in a great discussion
            hosted by <span className="text-yellow">Active Surveillance Patients International (ASPI)</span> on
            Saturday February 24th. The Program, How AI will Decrease Over
            Diagnosis and Overtreatment in Cancer, featured many exciting
            speakers and was attended by hundreds of prostate cancer active
            surveillance patients.
          </p>
        </div>
        <div className="mt-5">
          <div className="col-12 flex gap-8">
            <div className="col-2">
              <img src={BrunoBarrey} />
            </div>
            <div className="col-10">
              <p className="text">
                <span className="text-yellow">Bruno Barrey</span> a robotics
                engineer from suburban Detroit, discussed how he was able to
                avoid Androgen Deprivation Therapy (ADT) because of an analysis
                from ArteraAI as he transitioned from active surveillance to
                radiation treatment.
              </p>
            </div>
          </div>
          <div className="col-12 flex gap-8 mt-8">
            <div className="col-2">
              <img src={DanielSpratt} />
            </div>
            <div className="col-10">
              <p className="text">
                <span className="text-yellow">Dr. Daniel Spratt</span>, Chair of
                Radiation Oncology at University Hospitals in Cleveland and
                Bruno’s doctor, discussed ADT in detail including side effects,
                the role it plays in the transitioning from active surveillance
                to radiation, and how ArteraAI can spare most men from ADT.
              </p>
            </div>
          </div>
          <div className="col-12 flex gap-8 mt-8">
            <div className="col-2">
              <img src={TimShowalter} />
            </div>
            <div className="col-10">
              <p className="text">
                <span className="text-yellow">Dr. Tim Showalter</span>, Chief
                Medical Officer at ArteraAI discussed Atera’s current
                development including access for prostate cancer patients and
                the process for CMS and Medicare reimbursement for the
                technology. Artera’s technology is a multimodal artificial
                intelligence biomarker test that leverages a unique algorithm to
                access digital images from a patient’s prostate biopsy.
              </p>
            </div>
          </div>
          <div className="col-12 flex gap-8 mt-8">
            <div className="col-2">
              <img src={DrWojno} />
            </div>
            <div className="col-10">
              <p className="text">
                <span className="text-yellow">Dr. Wojno</span> highlighted
                Immunis.Ai’s innovative technology which is a liquid biopsy test
                that uses AI to define clinical cutpoints for guidance regarding
                entering, continuing, or stopping active surveillance. He
                discussed the promising data from their most recent study and
                how Immunis.AI’s technology can aid in active surveillance
                decision-making without the use of regular, invasive prostate
                biopsies.
              </p>
            </div>
          </div>
        </div>
      </ArticleStructure>

      <Footer></Footer>
    </>
  )
}
