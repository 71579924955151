import Footer from "../../../components/layout/footer"
import Navbar from "../../../components/layout/header"
import { ROUTES } from "../../../constants/routes"

import stckBackground from "../../../assets/images/backgroudImages/LateBreakingPosterPresentationAtAACR2019.jpg"

import ArticleStructure from "../../../components/articleStructure"

export default function index() {

  return (
    <>
      <Navbar></Navbar>

      <ArticleStructure
        location="Atlanta"
        date="April 1, 2019"
        title="OncoCell Announces Late-Breaking Poster Presentation at AACR 2019 on a Noninvasive Blood-Based Assay for Prostate Cancer Prognosis"
        initialText={<>OncoCell MDx, a company developing novel noninvasive diagnostic and prognostic tests, will present results from a feasibility study of a new prostate cancer prognostic assay in a late-breaking poster session at the <a className="underlined" href="https://www.aacr.org/professionals/meetings/previous-aacr-meetings/previous-aacr-meetings-2019/">American Association of Cancer Research (AACR) Annual Meeting</a> tomorrow. The study demonstrates that the blood-based immunogenomics RNA expression assay provides a prognostic summary comparable to that of prostate biopsy.</>}
        backgroundImage={stckBackground}
        previousNews={ROUTES.article1}
        aboutEnabled={true} netxNews={ROUTES.article9}
      >
        <div className="pt-3">
          <p className="text">
          OncoCell’s Subtraction-Normalized Expression of Phagocytes (SNEP) based platform, invented by Professor Amin Kassis, while at Harvard Medical School, uses a proprietary algorithm to interrogate changes in gene expression of two immune cell types consequent to prostate cancer including phagocytic (CD14) and non-phagocytic (CD2) cells, filters out intrinsic genomic variation not related to the disease, and identifies and validates prostate cancer-specific signatures. A study of blood samples from 713 prostate cancer patients showed the platform provides a prognostic summary including tumor Gleason grade distribution, size/volume and heterogeneity that is comparable to prostate biopsy information, and that it stratifies patients with aggressive disease that need life-saving treatment from those with indolent disease.
          </p>
        </div>
        <div className="mt-3">
          <p className="text">
          In the study, CD2 and CD14 cells were isolated from peripheral blood samples and mRNA samples were extracted and sequenced from each cell type. Transcripts with quantifiable expression changes were used to train OncoCell’s proprietary SNEP-based algorithm to stratify the patients into those who need treatment versus surveillance. A genomic signature was identified that is predictive of Gleason grade, cores positive and maximum involvement. Signature scores were significantly associated with these endpoints and an Aggressiveness Index that incorporates the three endpoints. Samples from two independent sets of naïve patients (n=305 and n=470) validated the results.
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
          We are encouraged by the results of these study findings, which suggest this assay may play an important role in advancing clinical management of prostate cancer patients.
            <p className="attribution text">
            Philip Kantoff, M.D., Clinical Advisor to OncoCell and Chair of the Department of Medicine, Memorial Sloan Kettering Cancer Center.
            </p>
          </p>
        </div>
        <div className="mt-5">
          <p className="text">
          Details of the study are being presented April 2 from 1:00 p.m. to 5:00 p.m. EDT in a poster session titled <span className="text-yellow">Immuno-genomic detection and prognostication of aggressive prostate cancer phonotypes by next-generation RNA sequencing</span> (<a href="https://www.abstractsonline.com/pp8/#!/6812/presentation/9127" className="underlined">https://www.abstractsonline.com/pp8/#!/6812/presentation/9127</a>) at the Georgia World Congress Center, Exhibit Hall B, Poster Section 41, Poster Board Number 19.
          </p>
        </div>
      </ArticleStructure>

      <Footer></Footer>
    </>
  )
}
