import Footer from "../../../components/layout/footer"
import Navbar from "../../../components/layout/header"
import { ROUTES } from "../../../constants/routes"

import stckBackground from "../../../assets/images/backgroudImages/DiscoveryCancerRelatedSignals.jpg"

import ArticleStructure from "../../../components/articleStructure"

export default function index() {
  return (
    <>
      <Navbar></Navbar>

      <ArticleStructure
        location="ROYAL OAK, Michigan"
        date="September 21, 2021"
        title="Immunis.AI Presents Discovery of Cancer-Related Signals in Circulating Monocytes"
        initialText=""
        backgroundImage={stckBackground}
        previousNews={ROUTES.article6}
        aboutEnabled={true} netxNews={ROUTES.article7}
      >
        <div className="pt-3">
          <p className="text">
            Immunis.AI, Inc., an immunogenomics platform company developing
            noninvasive blood-based tests to optimize patient care, today
            announced it has presented key findings in a poster presentation at
            the International Conference on Clinical and Single Cell
            Analysis held in Lisbon, Portugal. The presentation reported on the
            discovery of signals of prostate (cancer) origin within circulating
            monocytes and supports the company’s proprietary immunogenomic
            approach to early detection and grading of cancer and other
            diseases.
          </p>
        </div>
        <div className="mt-3">
          <p className="text">
            The goal of this study was to uncover changes in the phagocytic
            activity and evidence of phagocytosed RNA from cancer cells in
            circulating monocytes of prostate cancer patients. Solid tumors shed
            millions of cells per day per gram of tumor, however patients do not
            present with millions of circulating tumor cells. The innate immune
            system reacts to foreign insult in several unique ways, including
            phagocytosis. Circulating tumor cells, and those undergoing
            apoptosis, are cleared by the mononuclear phagocyte system.
          </p>
        </div>
        <div className="mt-5 ">
          <p className="ml-2 text">
            In the study, signals from cells of prostate (cancer) origin within
            circulating monocytes were enriched in blood drawn from men with
            prostate cancer (cases) relative to samples from men that were
            recently treated by radical prostatectomy, men with benign prostate
            biopsy or young healthy men (the latter three serving as controls).
            Circulating monocytes were isolated and purified using CD14+
            immunomagnetic beads (Miltenyi Biotec, Inc) and processed by single
            cell RNA sequencing using the 10X Genomics protocol followed by
            paired-end sequencing on an Illumina NovaSeq instrument.
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
            Evidence of disease signal in circulating monocytes and changes in
            phagocytic activity underpin one of the cornerstones of our
            proprietary Intelligentia™ platform. These results hold the promise
            for exciting new insights into the immune response in cancer
            patients and can help identify important upregulated or
            downregulated genes associated with the presence and aggressiveness
            of a particular disease, further supporting model development.
            <p className="attribution text">
              Dr. Leander Van Neste, Chief Scientific Officer of Immunis.AI
            </p>
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
            The innate immune system is on the front line of defense against
            disease and can serve as an important early warning mechanism for
            detection. Comparing the differential gene expression patterns of
            purified immune cell populations, using the patient as their own
            control to normalize and enhance the signal of the underlying
            pathology, can help us provide individualized diagnostic,
            prognostic, therapeutic response and disease monitoring assessment.
            <p className="attribution text">
              Dr. Kirk Wojno, Chief Medical Officer of Immunis.AI
            </p>
          </p>
        </div>
      </ArticleStructure>

      <Footer></Footer>
    </>
  )
}
