import CountUp from 'react-countup'
import Footer from "../../components/layout/footer"
import Navbar from "../../components/layout/header"

// import bladder from "../../assets/images/iconsVector/bladderVector.svg"
// import kedney from "../../assets/images/iconsVector/kidneyVector.svg"
// import prostate from "../../assets/images/iconsVector/prosatevector.svg"
import topArrow from "../../assets/images/iconsVector/topArrowVector.svg"
import viceVersa from "../../assets/images/iconsVector/viceVersa.svg"
import hospitalBedIcon from "../../assets/images/iconsVector/hospital-bed_ic.svg"
import microscopeIcon from "../../assets/images/iconsVector/microscope_ic.svg"
import pillsIcon from "../../assets/images/iconsVector/pills_ic.svg"
import reportIcon from "../../assets/images/iconsVector/report_ic.svg"
import scalpelIcon from "../../assets/images/iconsVector/scalpel_ic.svg"
import stethoscopeIcon from "../../assets/images/iconsVector/stethoscope_ic.svg"
import lineChartImage from "../../assets/images/png/lineChartDesktop.webp"

const MedicalNeed = () => {
  const analysisKidneyStats = [
    { count: 70000, isAnimated: true, detail: "People diagnosed with Kidney Cancer", icon: microscopeIcon },
    { count: 214000, prefix: '~', isAnimated: true, detail: "People living with Kidney Cancer", icon: stethoscopeIcon },
    { count: 50, suffix:"%", isAnimated: true, detail: "Small Renal Masses (SRM)", icon: hospitalBedIcon },
    { count: 40, suffix:"%", isAnimated: true, detail: "SRMs are benign", icon: reportIcon },
    { count: 80, suffix:"%", isAnimated: true, detail: "malignant SRM are low grade, early stage", icon: reportIcon },
    { count: 2, prefix: '<', suffix:"%", isAnimated: true, detail: "malignant SRM will become metastatic", icon: reportIcon },
    { count: 6000, prefix: '>', isAnimated: true, detail: "Men Over-Treated with Surgery", icon: scalpelIcon },
    { count: "", isAnimated: false, detail: "" },
    { count: "60-80%", isAnimated: false, detail: "of all cases potential for AS", icon: pillsIcon },
  ]

  const prostyateAnalysisStats = [
    { count: 248530, isAnimated: true, detail: "Men diagnosed with Prostate Cancer", icon: microscopeIcon },
    { count: 712000, prefix: '~', isAnimated: true, detail: "Men eligible for Active Surveillance", icon: stethoscopeIcon },
    { count: 198000, prefix: '~', isAnimated: true, detail: "Men Opting for Definitive Treatment", icon: scalpelIcon },
    { count: 50000, prefix: '~', isAnimated: true, detail: "Men Opting for Active Surveillance", icon: reportIcon },
    { count: 80000, prefix: '~', isAnimated: true, detail: "Men Over-Treated", icon: hospitalBedIcon },
    { count: 15000, prefix: '~', isAnimated: true, detail: "Men Under-Treated", icon: pillsIcon },
  ]

  const bladderAnalysisStats = [
    { count: 83000, isAnimated: true, detail: "People Diagnosed with Bladder Cancer", icon: microscopeIcon },
    { count: 712000, isAnimated: true, prefix: '~', detail: "People Living with Bladder Cancer", icon: stethoscopeIcon },
  ]

  const bladderOveruseDetails = [
    { icon: "", details: "Upper Tract Imaging(2x year)" },
    { icon: "", details: "Cystoscopy (3x year)" },
    { icon: "", details: "Cytologic testing (3x year)" },
  ]

  const imagesArray: any = []
  bladderOveruseDetails.forEach((x) => {
    const obj = x?.icon
    const values = Object.values(obj)
    imagesArray.push(values[0] ? values[0] : "")
  })

  return (
    <>
      <Navbar></Navbar>
      <div className="medicalNeedContainer">
        <div className="container py-5 px-sm-5 mt-32">
          <div className="flex justify-start">
            <span className="medicalNeedHeading  mt-2 text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
              Non-invasive Blood-based Diagnostics for Active Surveillance
            </span>
          </div>
          <div className="flex justify-start">
            <p className="medicalNeedSubHeading">
              New Medical Treatment Modality
            </p>
          </div>

          <div className="medicalNeedDescription mt-4">
          A treatment plan that involves closely watching a patient’s condition but not giving any intrusive treatment unless there are changes in test results that show the condition is getting worse. <span className="gradient-text text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">Active surveillance is used to avoid or delay the need for treatments</span> such as radiation therapy or surgery."
          </div>

          <div className="medicalSubDesription mt-3">National Cancer Institute</div>

          <div className="divider"></div>

          <div className="surveillance-text">The Medical Need for Surveillance</div>
          <div className="surveillance-subtext mb-5">Immunis assays can provide broad reach into multiple indications</div>

          <div className="focus-diseases-text mb-4">Active Surveillance for Early-Stage Cancer Requires Active Participation by Patient and Clinician</div>

          <div className="Diagnosisdetails mb-5">
            Active Surveillance was supposed to reduce the number of unnecessary treatments. However, in population based studies, it has been shown that adherence to established clinical guidelines is only 15%-30% at 18 months after a patient is put on Active Surveillance. Therefore, many cancers will continue to grow when remaining unchecked. Improving compliance is an absolute necessity if the benefits of Active Surveillance are to be realized. <br/><br/>
            Patients forgo regular visits to their physician because: repeat biopsies and MRI imaging are a discomfort to the patient.<br/><br/>
            Immunis.AI provides a <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">simple blood test</span> that can be performed in the physician’s office thereby greatly increasing patient adherence to and compliance with established Active Surveillance protocols.<br/><br/>
            In addition, the Immunis.AI test reports better performance than currently marketed tissue-based tests and MRI.
          </div>

          <div className="focus-diseases-text mb-4">Focus on Urological Diseases</div>

          {/* ProstateCancer */}
          <div className="flex justify-center">
            <div className="mt-5 medicalDetailsDiv w-100">
              <div className="p-4">
                <div className="flex diagnosisHeadings">
                  {/* <span className="diagnosisImage">
                    <img src={prostate} />
                  </span> */}
                  <span className="daignosisName">PROSTATE CANCER</span>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                  {prostyateAnalysisStats.map((data: any, index) => (
                    <div key={index}>
                      <div className="px-md-5 py-md-5 py-4 syntax">
                        <div className="flex align-items-center">
                          <img className="me-3" src={data.icon} />
                          { !data.isAnimated ? (
                            <span className="count mt-2">
                              {data?.prefix ? data?.prefix : ''}{data?.count ? data?.count : ""}{data?.suffix ? data?.suffix : ''}
                            </span>
                          ) : (
                            <>
                              <CountUp className="count mt-2" suffix={data?.suffix ? data?.suffix : ''} prefix={data?.prefix ? data?.prefix : ''} end={data.count} duration={5} />
                            </>  
                          )}
                        </div>  
                        <p className="Diagnosisdetails">
                          {data?.detail ? data?.detail : ""}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="analysisDiv chartDiv">
                <div className="chart-heading text-center">Cumulative Number of AS Patients Monitored</div>
                <div className="chart-image mt-4">
                  <img src={lineChartImage} className="img-fluid w-100" />
                </div>
                <div className="source-subheading text-center mt-4">Source: ACS 2021, NCI SEER Database 2021</div>
                <div className="chart-heading text-center my-4">Significant Opportunity to Address Expanding Adoption of AS of Prostate Cancer with First Liquid Biopsy Test</div>
              </div>
            </div>
          </div>

          {/* kidney */}
          <div className="flex justify-center">
            <div className="mt-5 medicalDetailsDiv w-100">
              <div className="p-4">
                <div className="flex diagnosisHeadings">
                  {/* <span className="diagnosisImage">
                    <img src={kedney} />
                  </span> */}
                  <span className="daignosisName">KIDNEY CANCER</span>
                </div>

                <div className="grid gap-0 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                  {analysisKidneyStats.map((data: any, index) => (
                    <div key={index}>
                      <div className="px-md-5 py-md-5 py-4 syntax">
                      <div className="flex align-items-center">
                          <img className="me-3" src={data.icon} />
                          { !data.isAnimated ? (
                          <span className="count mt-2">
                            {data?.prefix ? data?.prefix : ''}{data?.count ? data?.count : ""}{data?.suffix ? data?.suffix : ''}
                          </span>
                        ) : (
                          <>
                            <CountUp className="count mt-2" suffix={data?.suffix ? data?.suffix : ''} prefix={data?.prefix ? data?.prefix : ''} end={data.count} duration={5} />
                          </>  
                        )}
                        </div>
                        <p className="Diagnosisdetails">
                          {data?.detail ? data?.detail : ""}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Bladder */}
          <div className="flex justify-center">
            <div className="mt-5 medicalDetailsDiv w-100">
              <div className="p-4">
                <div className="flex diagnosisHeadings">
                  {/* <span className="diagnosisImage">
                    <img src={bladder} />
                  </span> */}
                  <span className="daignosisName">BLADDER CANCER</span>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
                {bladderAnalysisStats.map((data: any, index) => (
                  <div key={index}>
                    <div className="px-md-5 py-md-5 py-4 px-4 syntax">
                      <div className="flex align-items-center">
                        <img className="me-3" src={data.icon} />
                        { !data.isAnimated ? (
                          <span className="count mt-2">
                            {data?.prefix ? data?.prefix : ''}{data?.count ? data?.count : ""}{data?.suffix ? data?.suffix : ''}
                          </span>
                        ) : (
                          <>
                            <CountUp className="count mt-2" suffix={data?.suffix ? data?.suffix : ''} prefix={data?.prefix ? data?.prefix : ''} end={data.count} duration={5} />
                          </>  
                        )}
                      </div>
                      <p className="Diagnosisdetails">
                        {data?.detail ? data?.detail : ""}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="analysisDiv px-sm-4 px-3 py-4 py-sm-5">
                <div className="pb-sm-4 px-sm-4 px-md-3 px-0">
                  <div className="flex align-items-center">
                    <img className="me-3" src={reportIcon} />
                    <span className="overUseHeading">OVERUSE OF</span>
                  </div>  
                </div>

                <div className="grid gap-0 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                  {bladderOveruseDetails.map((data: any, index) => (
                    <div key={index}>
                      <div className="px-md-3 syntax">
                        <div className="flex">
                          <img
                            src={imagesArray[index] ? imagesArray[index] : ""}
                          />
                          <p className="Diagnosisdetails">
                            {data?.details ? data?.details : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">
                <div className="px-md-5 py-md-5 py-4 px-4 syntax">
                  <div className="flex align-items-center">
                    <img className="me-3" src={hospitalBedIcon} />
                    <span className="count mt-2">50%</span>
                  </div>
                  <p className="Diagnosisdetails">Low Grade Ta NMIBC</p>
                </div>

                <div className="px-md-5 py-md-5 py-4 px-4 syntax">
                  <div className="flex">
                    <img
                      src={topArrow}
                    />
                    <p className="ml-2 Diagnosisdetails">
                      {'Diagnoses'}
                    </p>
                  </div>
                  <div className="flex mt-1">
                    <img
                      src={topArrow}
                    />
                    <p className="ml-2 Diagnosisdetails">
                      {'Treatment'}
                    </p>
                  </div>
                  <div className="flex mt-1">
                    <img
                      src={viceVersa}
                    />
                    <p className="ml-2 Diagnosisdetails">
                      {'Mortality'}
                    </p>
                  </div>
                </div>

                <div className="px-md-5 py-md-5 pt-4 pb-5 px-4 syntax">
                  <div className="flex align-items-center">
                    <img className="me-3" src={pillsIcon} />
                    <span className="count mt-2">50-70%</span>
                  </div>
                  <p className="Diagnosisdetails">of all cases potential for AS</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="sources-acs-text">Sources: ACS 2021, NCI SEER Database 2021; Prostate cancer upgrading or downgrading of biopsy Gleason scores at radical prostatectomy: prediction of “regression to the mean” using routine clinical features with correlating biochemical relapse rates, Altok et al, Asian J of Andrology 2019; Over half of contemporary clinical Gleason 8 on prostate biopsy are downgraded at radical prostatectomy, Qi et al, Can J Urol 2017; Adverse Pathologic Findings for Men Electing Immediate Radical Prostatectomy Defining a Favorable Intermediate-Risk Group, Patel et al, JAMA Onc 2017; Comparative Analysis of Biopsy Upgrading in Four Prostate Cancer Active Surveillance Cohorts, Lurdes et al, Ann Intern Med 2018; Active Surveillance of Renal Masses: The Role of Radiology, Schieda et al., Radiology 2022; 302:11–24, https://doi.org/10.1148/radiol.2021204227; Active surveillance for non-muscle invasive bladder cancer: A systematic review and pooled-analysis, Petrelli etal., Cancer Treatment and Research Communications 27 2021.</div>

        </div>
      </div>
      <Footer></Footer>
    </>
  )
}
export default MedicalNeed
