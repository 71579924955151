
import { ROUTES } from "./routes"
export const InfoCardNews = [
  {
    date_time:"March 28, 2024",
    image:"ParticipationInActiveSurveillancePatientsInternational",
    detail:"PARTICIPATION IN Active Surveillance Patients International (ASPI)",
    detailLong:"Dr. Kirk Wojno, CMO, Immunis.Ai participated in a great discussion hosted by Active Surveillance Patients International (ASPI) on Saturday February 24th. The Program, How AI will Decrease Over Diagnosis and Overtreatment in Cancer, featured many exciting speakers and was attended by hundreds of prostate cancer active surveillance patients.h diagnosed prostate cancer. In the study, Immunis.AI’s non-invasive platform promises new standards of testing for active surveillance patients.",
    url:ROUTES.article11,
  },
  {
    date_time:"February 29, 2024",
    image:"BloodBasedImmune",
    detail:"Immunis.AI Announces Publication, in The Journal of Urology, of Results from Blood-Based Immune Profiling Study for Prostate Cancer Patients",
    detailLong:"IMMUNIS.AI, an immunogenomics company developing blood-based cancer surveillance technologies, today announced that results from a prostate cancer risk stratification study evaluating the company’s novel blood-based liquid biopsy platform were published in The Journal of Urology. using Amazon EC2 and Amazon EBS infrastructure... The challenge Immunis.AI faced was data ingestion and real-time analytics of its large immunotranscriptomic data sets and parallel processing of thousands of samples through its machine learning pipelines. Through the collaboration with Mission and AWS, the ingestion of data by Immunis.AI, which took two weeks to finish manually, can be completed within hours.",
    url:ROUTES.article10,
  },
  {
    date_time:"October 11, 2021",
    image:"liquidBiopsy",
    detail:"Immunis.AI Reports Results of a Prospective Study of its RNAseq-Based Liquid Biopsy in Early-Stage Prostate Cancer",
    detailLong:"Study findings validate the potential of the company’s proprietary Intelligentia platform to detect early-stage cancer and to stratify risk in men considering active surveillance of prostate cancer. The results also illustrate the potential of the company’s proprietary immunogenomic platform to address screening, treatment decision making, and minimal residual disease detection.",
    url:ROUTES.article9,
  },
  {
    date_time:"October 5, 2021",
    image:"ChoosenByAWS",
    detail:"Immunis.AI Chosen by Amazon Web Services to Showcase its Cloud-Based Genomic Pipeline for Machine Learning",
    detailLong:"Immunis.AI,  Inc., today announced that Amazon Web Services  (AWS) will showcase the company’s cloud-based genomic pipeline for machine learning. In collaboration with Mission Cloud Services, the platform will be highlighted in a virtual event, Behind the Innovation, hosted by AWS, today.The challenge Immunis.AI faced was data ingestion and real-time analytics of its large immunotranscriptomic data sets and parallel processing of thousands of samples through its machine learning pipelines. Through the collaboration with Mission and AWS, the ingestion of data by Immunis.AI, which took two weeks to finish manually, can be completed within hours.",
    url:ROUTES.article8,
  },
  {
    date_time:"September 29, 2021",
    image:"AchievesDevelopmentMilestones",
    detail:"Immunis.AI Achieves Development Milestones and Additional Financing",
    detailLong:"Immunis.AI, Inc., today announced the close of additional financing that brings its total raised to $40 million to date for the ongoing development of its proprietary Intelligentia immunogenomic platform. The additional funding comes on the heels of successful completion of key development milestones, including three pivotal studies of the Intelligentia platform. Funding will support further validation and commercialization of the company’s first molecular test in prostate cancer and will also be used to expand development of new tests in breast, colon, lung cancer and other cancers.",
    url:ROUTES.article7,
  },
  {
    date_time:"September 21, 2021",
    image:"DiscoveryCancerRelatedSignals",
    detail:"Immunis.AI Presents Discovery of Cancer-Related Signals in Circulating Monocytes",
    detailLong:"Immunis.AI, Inc., an immunogenomics platform company developing noninvasive blood-based tests to optimize patient care, today announced it has presented key findings in a poster presentation at the International Conference on Clinical and Single Cell Analysis held in Lisbon, Portugal. The presentation reported on the discovery of signals of prostate (cancer) origin within circulating monocytes and supports the company’s proprietary immunogenomic approach to early detection and grading of cancer and other diseases.",
    url:ROUTES.article6,
  },
  {
    date_time:"May 11, 2021",
    image:"AnnouncesAdditionalPatentsGrantedByUSPTO",
    detail:"Immunis.AI Announces Additional Patents Granted by USPTO, Strengthening the Company’s Intellectual Property Position in Novel Methods and Technologies for Cancer Detection",
    detailLong:"Immunis.AI today announced that it has received three additional patents from the United State Patent and Trademark Office (USPTO). Patent No. 10934588, Patent No. 10934589 and Patent No. 11001894 are directed to technology for processing liquid biopsy samples and include protection of novel methods of detecting signatures of disease or conditions in bodily fluids. Together, they cover technology for multiomic testing, using RNA, DNA and proteins from a variety of phagocytic and non-phagocytic cell type sources.",
    url:ROUTES.article5,
  },
  {
    date_time:"July 14, 2020",
    image:"TwoPatentsGrantedByTheEuropeanPatentOffice",
    detail:"Immunis.AI Announces Two Patents Granted by the European Patent Office",
    detailLong:"Immunis.AI, a privately held immunogenomics company with a patented liquid biopsy platform that offers unique insights into disease biology and individualized assessment, today announced that the European Patent Office (EPO) has granted two patents that protect the company’s innovations related to cancer detection and monitoring in the European market. ",
    url:ROUTES.article4,
  },
  {
    date_time:"April 21, 2020",
    image:"PatentsGrantedByUSPTO",
    detail:"Immunis.AI Announces Patents Granted by USPTO, Strengthening the Company’s Intellectual Property Position in Novel Methods and Technologies for Cancer Detection and Prognosis",
    detailLong:"Immunis.AI today announced that it has received an additional patent from the United State Patent and...",
    url:ROUTES.article3,
  },
  {
    date_time:"July 18, 2019",
    image:"OncoCellMDxAppointsRenownedUrologicPathologistAsChiefMedicalOfficer",
    detail:"OncoCell MDx Appoints Renowned Urologic Pathologist as Chief Medical Officer",
    detailLong:"OncoCell MDx, an immunogenomics platform company specializing in the delivery of novel, noninvasive molecular diagnostic assays, today announced that Kirk Wojno, M.D., has joined the company’s leadership...",
    url:ROUTES.article2,
  },
  {
    date_time:"April 1, 2019",
    image:"LateBreakingPosterPresentationAtAACR2019",
    detail:"OncoCell Announces Late-Breaking Poster Presentation at AACR 2019 on a Noninvasive Blood-Based Assay for Prostate Cancer Prognosis",
    detailLong:"OncoCell’s Subtraction-Normalized Expression of Phagocytes (SNEP) based platform, invented by Professor Amin Kassis, while at Harvard Medical School, uses a proprietary algorithm to interrogate changes in gene expression of two immune cell types consequent to prostate cancer including phagocytic (CD14) and non-phagocytic (CD2) cells, filters out intrinsic genomic variation not related to the disease, and identifies and validates prostate cancer-specific signatures. A study of blood samples from 713 prostate cancer patients showed the platform provides a prognostic summary including tumor Gleason grade distribution, size/volume and heterogeneity that is comparable to prostate biopsy information, and that it stratifies patients with aggressive disease that need life-saving treatment from those with indolent disease.",
    url:ROUTES.article1,
  },
]