import Footer from "../../components/layout/footer"
import Navbar from "../../components/layout/header"

export default function index() {
  const privacyPolicy = [
    {
      heading: "If you visit our site to read or download information",
      paragraph:
        "The Immunis.AI web site logs IP addresses, browser types and other information for system administration purposes. We use this information to measure the number of visitors to each section of our site and to help make our site more useful. These logs are analyzed to improve the value of the information available on the web site. A user’s session may be tracked in some cases, but we do not link sessions or IP addresses to any personally identifiable information.",
    },
    {
      heading:
        "If you choose to identify yourself by sending us an email or filling out an online form",
      paragraph:
        "We use email links, forms and email list subscriptions located on contact pages and other areas of the site to communicate with users and to allow them to contact us directly with any questions or comments. This information is used to respond directly to questions or comments. ANY COMMUNICATION OR MATERIAL THAT YOU TRANSMIT TO OUR SITE, BY ELECTRONIC MAIL OR OTHERWISE, INCLUDING ANY DATA, QUESTIONS, COMMENTS, SUGGESTIONS OR THE LIKE, IS, AND WILL BE TREATED AS NON-CONFIDENTIAL AND NONPROPRIETARY INFORMATION. YOU SHOULD NOT COMMUNICATE ANY PERSONAL HEALTH INFORMATION. We may store the information users send in order to respond to requests, improve the site or track Quality Assurance related incidents.<br/><br/>In some cases, authorized third-party agents may need access to some of your personal information in order for us to complete a request that you have made. For example, if we need to ship something to you, we may share your name and address with a shipping company. We only provide third-party agents with the minimum amount of information needed to complete the requested service or transaction. By providing Immunis.AI with your personal information, you agree to the foregoing. Otherwise, if you choose to provide us with personal information, either by email or by filling out a form and submitting it to us through the web site, this information will not be released, disclosed, sold or transferred to any outside source unless required for law enforcement or by statute, unless you have granted us permission to do so.",
    },
    {
      heading: "To unsubscribe from Immunis.AI email lists",
      paragraph:
        "Instructions for unsubscribing from Immunis.AI’s email lists are sent with each email message. Alternatively, you may request removal from our mailing list at public.relations@immunis.ai.",
    },
    {
      heading: "Security of your information",
      paragraph:
        "We do not forward your mail outside of Immunis.AI nor do we collect your name and email address for any purpose other than to respond to your query. Nevertheless, you should be aware that email is not necessarily secure against interception. If your communication contains sensitive or personal information, you may want to send it by postal mail.",
    },
    {
      heading: "About links to outside web sites",
      paragraph:
        "This web site provides links to documents and information located on web sites maintained by other organizations. Once you access an individual document or information that links you to another web site, you are subject to the privacy policy and other terms and conditions of use of the web site containing that document. Since we do not control these web sites, we encourage you to review the privacy policies posted on these third-party sites. Immunis.AI disclaims any responsibility for the policies, procedures or practices followed by other, non-Immunis.AI web sites.",
    },
    {
      heading: "About “Cookies”",
      paragraph:
        "While visiting sites within the Immunis.AI domain, you may occasionally encounter a web page that employs “cookies”. A cookie is a small file that a web site transfers to your computer’s hard disk allowing our server to “remember” specific information about your session while you are connected. Requests to send cookies from Immunis.AI web pages are not designed to collect information about you, but only about your browser “session.” To protect your privacy, be sure to close your browser completely after you have finished using a web site that uses cookies. If you are concerned about the potential use of information gathered from your computer by cookies, you can set your browser to prompt you before it accepts a cookie. Most Internet browsers have settings that let you identify and/or reject cookies.",
    },
    {
      heading: "Policy Updates",
      paragraph:
        "Immunis.AI reserves the right to change or modify the terms of this Privacy Policy at any time.",
    },
    {
      heading: "Terms of Use",
      paragraph:
        "You should also review the Legal Disclaimers for the Immunis.AI web site.",
    },
    {
      heading: "Contact Information",
      paragraph:
        "If you have any questions about the rights and restrictions above, please use the contact forms, phone numbers and/or email addresses listed on our Contact page.",
    },
  ]

  return (
    <>
      <Navbar></Navbar>
      <div className="termscontainer">
        <div className="container py-5">
          <div className="articleContantCont">
            <div className="termsBorder">
              <h2 className="terms-policy-heading text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
                Privacy Policy
              </h2>
            </div>
            <div>
              <p className="followingText py-3">
                This policy outlines how we treat information we learn about you
                from your visit to our web site.
              </p>
            </div>
            {privacyPolicy.map((data: any, index) => (
              <div key={index} className="my-5">
                <h6 className="bg-yellow">{data.heading}</h6>
                <p className="textItem mt-4" dangerouslySetInnerHTML={{ __html: data.paragraph }}></p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  )
}
