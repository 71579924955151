import Footer from "../../../components/layout/footer"
import Navbar from "../../../components/layout/header"
import { ROUTES } from "../../../constants/routes"

import stckBackground from "../../../assets/images/backgroudImages/OncoCellMDxAppointsRenownedUrologicPathologistAsChiefMedicalOfficer.jpg"

import ArticleStructure from "../../../components/articleStructure"

export default function index() {
  return (
    <>
      <Navbar></Navbar>

      <ArticleStructure
        location="ROYAL OAK, Michigan"
        date="July 18, 2019"
        title="OncoCell MDx Appoints Renowned Urologic Pathologist as Chief Medical Officer"
        initialText=""
        previousNews={ROUTES.article1}
        aboutEnabled={true} netxNews={ROUTES.article8}
      >
        <div className="mt-3 w-100">
          <img className="w-100" src={stckBackground} />
          <p className="mt-2 text-gray text-center">
            Kirk Wojno, M.D., is a nationally renowned urologic pathologist and
            currently serves as Chief Medical Officer for OncoCell MDx
          </p>
        </div>
        <div className="mt-20">
          <p className="text">
            OncoCell MDx, an immunogenomics platform company specializing in the
            delivery of novel, noninvasive molecular diagnostic assays, today
            announced that Kirk Wojno, M.D., has joined the company’s leadership
            team as its Chief Medical Officer. Dr. Wojno, a renowned urologic
            pathologist and expert in prostate biopsy interpretation, joins an
            expanding, experienced executive team. OncoCell MDx also recently
            announced the appointments of Mark McDonough as President and CEO
            and Christopher Thibodeau as Chief Operating Officer.
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
            OncoCell is in an exciting phase of growth. We are moving toward
            commercialization of our first immunogenomics test for prostate
            cancer, and we are continuing to develop our pipeline of noninvasive
            tests for other cancers and diseases. Dr. Wojno brings a wealth of
            knowledge of the company and the space, an illustrious reputation,
            expert knowledge of anatomic and molecular pathology, and deep
            experience running clinical affairs and laboratory operations. I’m
            really pleased to work with him, with Christopher and with the rest
            of the OncoCell team to drive transformational advances in patient
            care.
            <p className="attribution text">
              Mark McDonough, President and CEO, OncoCell MDx
            </p>
          </p>
        </div>
        <div className="mt-5">
          <p className="text">
            Dr. Wojno is a nationally renowned pathology expert in prostate
            cancer. He is the former head of Urologic Pathology at the
            University of Michigan, at Ascension Health System, and at national
            Ameripath Laboratories. He has more than 30 years in medical
            practice and most recently served as the Chief of Pathology and
            Laboratory Services and Director of Clinical Research for
            Comprehensive Urology located in Royal Oak, Michigan, one of the
            largest urology group practices in the United States. While at Johns
            Hopkins University, Dr. Wojno trained with the Brady Institute’s
            Jonathan Epstein, M.D. an internationally recognized authority in
            uropathology. Dr. Wojno received his medical degree from Wayne State
            University School of Medicine.
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
            Screening, diagnosis and management of prostate cancer continues to
            be challenging due to tumor heterogeneity and complexity. As a
            practicing pathologist over the past three decades, I’m delighted to
            work with OncoCell in addressing a significant need to improve
            patient selection for active surveillance and to provide a
            noninvasive method for annual monitoring. Our goal is to help reduce
            overtreatment of indolent disease along with patient anxiety. I am
            equally excited about the promise this technology offers across a
            broad spectrum of diagnostic indications, from cancer,
            cardiovascular, infectious and neurodegenerative disease, to immune-
            and kidney-related diseases.
            <p className="attribution text">
              Kirk Wojno, M.D., Chief Medical Officer, Immunis.AI
            </p>
          </p>
        </div>
      </ArticleStructure>

      <Footer></Footer>
    </>
  )
}
