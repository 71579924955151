import Footer from "../../components/layout/footer"
import Navbar from "../../components/layout/header"

import diagram from "../../assets/images/png/DiagramSection.png"
import increaseIcon from "../../assets/images/theme/ic_increase.svg"
import qualityIcon from "../../assets/images/theme/ic_quality.svg"

const ImmunisAISolution = () => {
  const immunisAiDetailArray = [
    {
      cardNumber: "01",
      details:
        "Immunis’ proprietary Subtraction Normalized Immunocyte Profiling (SNIP) technology subtracts gene expression (RNA) on a gene-by-gene basis from CD2+ and CD14+ Immune Cells",
    },
    {
      cardNumber: "02",
      details:
        "This normalizes, enhances, and amplifies the signal of the underlying pathology",
    },
    {
      cardNumber: "03",
      details: "AI-based models are trained on clinical cutpoints"
    },
    {
      cardNumber: "04",
      details:
        "Enables more sensitive small tumor profiling that can be used for screening, diagnosis, prognosis, surveillance, therapeutic response prediction, and disease monitoring",
    },
  ]
  return (
    <>
      <Navbar></Navbar>
      <div className="immunisSolutionContainer">
        <div className="container pb-5 mt-32">
          <div className="flex justify-start">
            <span className="immusisHeading mt-3 text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
              IMMUNIS.AI SOLUTION
            </span>
          </div>
          <div className="flex justify-start">
            <p className="immunisSubHeading">
              Immunis discovery platform offers a powerful blood-based solution
            </p>
          </div>

          <div className="grid gap-3 mt-5 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
            {immunisAiDetailArray.map((data: any, index) => (
              <div key={index}>
                <div className="immunisAiDetailsCard">
                  <div className="mb-2 card-number-text">{data?.cardNumber}</div>
                  <p className="ImmunisDetails">{data?.details}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="boxes-wrapper">
            <h3 className="active-surveillance-heading mb-10">
              Prostate Cancer - Active Surveillance
            </h3>
            <div className="md:flex ms:gap-0">
              <div className="box-card">
                <div className="flex items-center">
                  <div className="increase-icon mr-3">
                    <img src={increaseIcon} />
                  </div>
                  <div className="count-digit">+ 312%</div>
                </div>
                <div className="box-content mt-2">
                  <div className="number-info">more aggressive cancers detected than current active surveillance paradigm with only 32% compliance</div>
                </div>

                <div className="flex items-center mt-md-4 mt-5">
                  <div className="increase-icon mr-3">
                    <img src={increaseIcon} />
                  </div>
                  <div className="count-digit">+ 3X</div>
                </div>
                <div className="box-content mt-2">
                  <div className="number-info">more patients saved by curative therapy</div>
                </div>
              </div>
              <div className="box-card">
                <div className="flex items-center">
                  <div className="increase-icon mr-3">
                    <img src={increaseIcon} />
                  </div>
                  <div className="count-digit">90%</div>
                </div>  
                <div className="box-content mt-2">
                  <div className="number-info">NPV of 90% results in a significantly higher confident classification of patients that can remain on AS</div>
                </div>

                <div className="flex items-center mt-md-4 mt-5">
                  <div className="increase-icon mr-3">
                    <img src={increaseIcon} />
                  </div>
                  <div className="count-digit">+ 168%</div>
                </div>  
                <div className="box-content mt-2">
                  <div className="number-info">more effective than the current paradigm in classifying less aggressive cancers for AS</div>
                </div>
              </div>
            </div>  
          </div>

          <div className="flex justify-start">
            <span className="mt-5 targetApproachText">
              A Targeted Immunocellular and Immunogenomic Approach
            </span>
          </div>

          <div className="flex justify-center mt-3 mb-4">
            <img src={diagram} />
          </div>

          <div className="flex justify-start mb-4">
            <span className="mt-5 targetApproachText">
              Personalized Disease Definition 
            </span>
          </div>
          
          <div className="pt-3 personalized-disease-container pb-md-5 pb-5">
            <div className="md:grid h-full md:grid-cols-2 gap-4">

              <div className="disease-details">
                <div className="disease-card">
                  <div className="block-heading">Revolutionary Liquid Biopsy Technology</div>

                  <div className="block-description flex flex-start">
                    <div className="list-dot-section">
                      <div className="list-dot mt-2"></div>
                    </div>
                    <div className="block-description-content">Harnesses power of the immune system to enable earlier detection, higher precision, and individualized assessments using proprietary Subtraction Normalized Immunocyte Profiling (“SNIP”) process originally developed at Harvard Medical School and out-licensed to Immunis</div>
                  </div>
                </div>

                <div className="disease-card">
                  <div className="block-heading">Advanced Immunocellular Platform</div>

                  <div className="block-description flex flex-start">
                    <div className="list-dot-section">
                      <div className="list-dot mt-2"></div>
                    </div>
                    <div className="block-description-content">Enables detection of early disease from a single blood draw</div>
                  </div>
                  <div className="block-description flex flex-start mt-1">
                    <div className="list-dot-section">
                      <div className="list-dot mt-2"></div>
                    </div>
                    <div className="block-description-content">Platform opportunity is multi-cancer and multi-disease with potential to address further indications across metabolic, autoimmune, cardiovascular, infectious and neurodegenerative diseases</div>
                  </div>
                </div>

                <div className="disease-card">
                  <div className="block-heading">Validated Science and Targeted Development Strategy</div>

                  <div className="block-description flex flex-start">
                    <div className="list-dot-section">
                      <div className="list-dot mt-2"></div>
                    </div>
                    <div className="block-description-content">Three of the largest prospective studies performed in prostate cancer surveillance with recent scientific publication in Cells provides strong technology validation</div>
                  </div>
                  <div className="block-description flex flex-start mt-1">
                    <div className="list-dot-section">
                      <div className="list-dot mt-2"></div>
                    </div>
                    <div className="block-description-content">Additional scientific publications and studies in process providing further support and advancement along development pathway</div>
                  </div>
                </div>

                <div className="disease-card">
                  <div className="block-heading">Foundation to Support Rapid & Robust Commercial Potential</div>

                  <div className="block-description flex flex-start">
                    <div className="list-dot-section">
                      <div className="list-dot mt-2"></div>
                    </div>
                    <div className="block-description-content">Multiple Billions in total addressable market targeting surveillance indications (Active Surveillance, MRD)</div>
                  </div>
                  <div className="block-description flex flex-start mt-1">
                    <div className="list-dot-section">
                      <div className="list-dot mt-2"></div>
                    </div>
                    <div className="block-description-content">Portfolio Initially focused on the Urology Channel and expanding to address a broader set of therapeutic areas with individualized assays</div>
                  </div>
                  <div className="block-description flex flex-start mt-1">
                    <div className="list-dot-section">
                      <div className="list-dot mt-2"></div>
                    </div>
                    <div className="block-description-content">Low COGs and strong reimbursement expected</div>
                  </div>
                  <div className="block-description flex flex-start mt-1">
                    <div className="list-dot-section">
                      <div className="list-dot mt-2"></div>
                    </div>
                    <div className="block-description-content">Potential for a multi-disease application</div>
                  </div>
                </div>

                <div className="disease-card">
                  <div className="block-heading">Strong Intellectual Property Portfolio</div>

                  <div className="block-description flex flex-start">
                    <div className="list-dot-section">
                      <div className="list-dot mt-2"></div>
                    </div>
                    <div className="block-description-content">Extensive intellectual property estate providing comprehensive protection for the platform and commercial assays</div>
                  </div>
                  <div className="block-description flex flex-start mt-1">
                    <div className="list-dot-section">
                      <div className="list-dot mt-2"></div>
                    </div>
                    <div className="block-description-content">90 issued patents domestically and globally</div>
                  </div>
                </div>

              </div>

              <div></div>

            </div>
          </div>
          
          <div className="quality-test-section flex flex-col items-center justify-center">
              <div className="quality-icon mb-md-4 mb-3">
                <img src={qualityIcon} />
              </div>
              <h3 className="quality-immunis-heading pt-md-3 pt-2 mb-md-4 text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
                IMMUNIS.AI SOLUTION
              </h3>
              <div className="sub-heading mb-md-4">reports better performance than tissue-based tests and MRI</div>
              <div className="sub-heading">offers more attractive COGs, faster turn-around time and greater scalability</div>
          </div>

        </div>
      </div>

      <Footer></Footer>
    </>
  )
}
export default ImmunisAISolution
