
import Footer from "../../../components/layout/footer"
import Navbar from "../../../components/layout/header"
import { ROUTES } from "../../../constants/routes"

import stckBackground from "../../../assets/images/backgroudImages/AdditionalPatentsGrantedByUSPTO.jpg"

import ArticleStructure from "../../../components/articleStructure"

export default function index() {

  return (
    <>
      <Navbar></Navbar>

      <ArticleStructure
        location="ROYAL OAK, Michigan"
        date="May 11, 2021"
        title="Immunis.AI Announces Additional Patents Granted by USPTO, Strengthening the Company’s Intellectual Property Position in Novel Methods and Technologies for Cancer Detection"
        initialText=""
        previousNews={ROUTES.article7}
        aboutEnabled={true} netxNews={ROUTES.article6}
      >
        <div className="mt-3 w-100">
          <img className="w-100" src={stckBackground} />
          <p className="mt-2 text-gray text-center">
            United States Patent and Trademark Office (USPTO). The image was
            taken from{" "}
            <a
              className="underlined"
              href="https://www.uspto.gov/about-us/uspto-locations/alexandria-va/eastern-regional-outreach-office"
            >
              https://www.uspto.gov/about-us/uspto-locations/alexandria-va/eastern-regional-outreach-office
            </a>
          </p>
        </div>
        <div className="mt-20">
          <p className="text">
            Immunis.AI today announced that it has received three additional
            patents from the United State Patent and Trademark Office (USPTO).{" "}
            <span className="text-yellow">Patent No. 10934588</span>,{" "}
            <span className="text-yellow">Patent No. 10934589</span> and{" "}
            <span className="text-yellow">Patent No. 11001894</span> are
            directed to technology for processing liquid biopsy samples and
            include protection of novel methods of detecting signatures of
            disease or conditions in bodily fluids. Together, they cover
            technology for multiomic testing, using RNA, DNA and proteins from a
            variety of phagocytic and non-phagocytic cell type sources.
          </p>
        </div>
        <div className="mt-3">
          <p className="text">
            The patents additionally recognize new methods for detecting and
            comparing gene expression between at least one phagocytic cell and
            at least one non-phagocytic cell, and detecting and comparing gene
            expression between one hematopoietic phagocytic cell and one
            hematopoietic non-phagocytic cell.
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
            Immunis.AI holds one of the most robust patent portfolios in novel
            detection methods with the potential to overcome a limitation of
            circulating tumor cells (CTCs) and cell free DNA (cfDNA). The
            addition of these recent patents further strengthens our
            intellectual property position and underscores our commitment to
            innovation in advancing diagnostic development and delivery of high
            performing tests.
            <p className="attribution text">
              Christopher Thibodeau, President and CEO, Immunis.AI
            </p>
          </p>
        </div>
      </ArticleStructure>

      <Footer></Footer>
    </>
  )
}
