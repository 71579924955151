interface cardProps {
  img: string
  heading: string
  content: string
}
const DiagnosisCard = (props: cardProps) => {
  const data = props
  return (
    <>
      <div className="flex justify-start rounded-none card">
        <div className="px-3 px-md-5 py-3 DagnosiscardImage">
          <div className="flex justify-start">
            <img src={data?.img} />
          </div>
        </div>
        <div className="px-3 px-md-5 cardHeading">{data.heading}</div>
        <div className="DiagnosiscardContent">{data.content}</div>
      </div>
    </>
  )
}
export default DiagnosisCard
