import Footer from "../../../components/layout/footer"
import Navbar from "../../../components/layout/header"
import { ROUTES } from "../../../constants/routes"

import stckBackground from "../../../assets/images/backgroudImages/ChoosenByAWS.jpg"

import ArticleStructure from "../../../components/articleStructure"

export default function index() {

  return (
    <>
      <Navbar></Navbar>

      <ArticleStructure
        location="ROYAL OAK, Michigan"
        date="October 5, 2021"
        title="Immunis.AI Chosen by Amazon Web Services to Showcase its Cloud-Based Genomic Pipeline for Machine Learning"
        initialText=""
        backgroundImage={stckBackground}
        previousNews={ROUTES.article7}
        aboutEnabled={true} netxNews={ROUTES.article9}
      >
        <div className="pt-3">
          <p className="text">
            Immunis.AI, Inc., today announced that Amazon Web Services (AWS)
            will showcase the company’s cloud-based genomic pipeline for machine
            learning. In collaboration with Mission Cloud Services, the platform
            will be highlighted in a virtual event,{" "}
            <span className="text-yellow">Behind the Innovation</span>, hosted
            by AWS, today.
          </p>
        </div>
        <div className="mt-3">
          <p className="text">
            Immunis.AI engaged Mission, a partner with deep life science
            expertise, to design an AWS architecture to leverage Amazon S3
            alongside a backend data pipeline using Amazon EC2 and Amazon EBS
            infrastructure. The challenge Immunis.AI faced was data ingestion
            and real-time analytics of its large immunotranscriptomic data sets
            and parallel processing of thousands of samples through its machine
            learning pipelines. Through the collaboration with Mission and AWS,
            the ingestion of data by Immunis.AI, which took two weeks to finish
            manually, can be completed within hours.
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
            Machine learning presents its own set of unique challenges and
            managing the large data sets is a major problem in the field of
            genomics. Working with Mission and AWS to design an architecture to
            streamline our data ingestion and analytics, has enabled us to
            drastically accelerate development of our immunogenomic tests to
            improve diagnosis and treatment of cancer patients. We are pleased
            to have been chosen by AWS to highlight our architecture and our
            powerful partnership and the life changing outcomes it enables.
            <p className="attribution text">
              Geoffrey Erickson, a founder and Senior VP of Corporate
              Development at Immunis.AI
            </p>
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
            While it is still evolving, Mission provided Immunis.AI with a
            tested and proven blueprint for a viable research oriented genomic
            platform, all backed by AWS and ready to scale quickly and
            economically. We are pleased to support Immunis.AI’s important
            mission to develop tests that can improve the lives of cancer
            patients and proud of its Mission-built AWS infrastructure that is
            helping them.
            <p className="attribution text">
              Jonathan LaCour, Chief Technology Officer & Sr. Vice President
            </p>
          </p>
        </div>
        <div className="mt-5 ">
          <p className="ml-2 text">
            Immunis.AI continues to leverage its successful blueprint across
            several clinical studies, as it develops and plans to commercialize
            its products. Mission will also continue to help Immunis.AI with
            data modernization, including data lake and analytics initiatives on
            AWS.
          </p>
        </div>
      </ArticleStructure>

      <Footer></Footer>
    </>
  )
}
