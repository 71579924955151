import Footer from "../../../components/layout/footer"
import Navbar from "../../../components/layout/header"
import { ROUTES } from "../../../constants/routes"

import stckBackground from "../../../assets/images/backgroudImages/Blood-BasedImmune.png"
import nationalCancerInstitute from "../../../assets/images/theme/national-cancer-institute.png"

import ArticleStructure from "../../../components/articleStructure"

export default function index() {

  return (
    <>
      <Navbar></Navbar>

      <ArticleStructure
        location="ROYAL OAK, Michigan"
        date="February 29, 2024"
        title="Immunis.AI Announces Publication, in The Journal of Urology, of Results from Blood-Based Immune Profiling Study for Prostate Cancer Patients"
        initialText="IMMUNIS.AI, an immunogenomics company developing blood-based cancer surveillance technologies, today announced that results from a prostate cancer risk stratification study evaluating the company's novel blood-based liquid biopsy platform were published in The Journal of Urology."
        backgroundImage={stckBackground}
        previousNews={ROUTES.article9}
        aboutEnabled={true} netxNews={ROUTES.article11}
      >
        <div className="pt-3">
          <p className="text">
            Results from Blood-Based Liquid Biopsy Test Comparable to Those of
            Invasive Tissue Biopsy Molecular Tests in Stratifying Risk of
            Aggressive Prostate Cancer. The results validate the ability of
            theplatform to accurately stratify risk for patients with diagnosed
            prostate cancer. In the study, Immunis.AI’s non-invasive platform
            promises new standards of testing for active surveillance patients.
          </p>
        </div>
        <div className="mt-3">
          <p className="quote text">
            Study findings validate the potential of the company’s proprietary
            Intelligentia platform to detect early-stage cancer and to stratify
            risk in men considering active surveillance of prostate cancer. The
            results also illustrate the potential of the company’s proprietary
            immunogenomic platform to address screening, treatment decision
            making, and minimal residual disease detection.
            <p className="attribution text">
              Kirk Wojno, M.D., Chief Medical Officer, Immunis.AI
            </p>
          </p>
        </div>
        <div className="mt-3">
          <p className="text">
            Currently, only ~30% of patients remain compliant with active
            surveillance protocols, which involve having multiple biopsies,
            currently deemed the gold standard for surveillance. This alone
            limits the effectiveness of surveillance leading to worsened patient
            outcomes and missed opportunities for cure.{" "}
          </p>
        </div>
        <div className="mt-3">
          <p className="quote text">
            One of the big challenges in prostate cancer management is
            distinguishing those patients with early prostate cancer who are
            appropriate for active surveillance from those who need to be
            aggressively treated. The novel approach published by Immunis.AI
            utilizes the RNA signature created by the immune system’s response
            to cancer. The potential to better select patients would mark a
            significant advance in prostate cancer active surveillance,
            <p className="attribution text">
              Philip Kantoff, M.D., advisor to the company and author of the
              publication
            </p>
          </p>
        </div>
        <div>
          <h1 className="subtitle">Study Design and Results:</h1>
          <div className="mt-3 ">
            <p className="ml-2 text">
            In the study, peripheral blood samples were collected from 706 subjects with confirmed prostate cancer based on positive biopsy. Immune cells were isolated from these blood samples, and gene expression levels in the patient’s lymphocytes and monocytes were identified using RNA sequencing to evaluate the prostate cancer-specific immune profile of each patient.
            </p>
          </div>
          <div className="mt-3 ">
            <p className="ml-2 text">
            To characterize each patient’s immune profile, gene expression levels in their lymphocytes were subtracted from gene expression levels in their monocytes, a process called subtraction-normalization that eliminates the confounding effects of patient-to-patient variability in gene expression levels. Using AI predictive modeling, the normalized immune profile of each patient was evaluated against known immune signatures for indolent or aggressive prostate cancer.
            </p>
          </div>
          <div className="mt-3">
            <p className="ml-2 text">
            The model produced an individual risk score for each patient which categorized them into one of three groups: very low-risk (90% NPV), low-risk and high-risk for harboring clinically significant prostate cancer.
            </p>
          </div>
          
          <div className="mt-3">
            <p className="ml-2 text">
            Data from the study shows that the immune profile is effective as a biomarker of prostate cancer severity and is significantly better than clinical factors alone in predicting which patients have aggressive cancer. The individual risk score produced by the model was successful at accurately establishing the likelihood of each patient’s cancer being clinically significant and requiring treatment.
            </p>
          </div>
          <div className="mt-3">
          <p className="quote text">
          When a patient has cancer, their immune cells arm themselves to fight it off, which is reflected in changing levels of gene expression in those cells. While the actual gene expression levels in lymphocytes and monocytes may vary from patient to patient, the relationship between the two gene expression levels produces a distinct immune signature for patients with indolent cancer and those with aggressive cancer, helping to identify the presence and severity of cancer without making a general comparison to a standardized patient population. By looking at the personalized immune profile specific to each patient instead of comparing to population averages, we can more accurately assess aggressiveness of an individual’s cancer and better inform treatment decisions.
            <p className="attribution text">
            Kirk Wojno, M.D., Chief Medical Officer, Immunis.AI
            </p>
          </p>
        </div>
        </div>
        <div className="mt-3">
          <img className="blueRibbonImage" src={nationalCancerInstitute} alt="" />
        </div>
        <div className="mt-3">
        <p className="quote text">
        Modifying active surveillance protocols based on the patient’s immune response to their own prostate cancer is a completely novel and very exciting concept. Immunis.AI ‘s liquid biopsy test  represents a new frontier to developing dynamic risk-based active surveillance strategies that can improve patient care, improve the active surveillance experience and lessen the patient burden of intensive monitoring, decreasing the need for surveillance prostate biopsies
            <p className="attribution text">
            Jason Hafron, M.D., Chief Medical Officer, Michigan Institute of Urology and author of the publication.
            </p>
            </p>
        </div>
        <div className="mt-4">
          <p className="ml-2 text">
          Immunis.AI intends to commercialize the test in the U.S. and Europe. In the U.S. alone, the total addressable market is estimated to be up to $4 billion.
          </p>
        </div>
      </ArticleStructure>

      <Footer></Footer>
    </>
  )
}
