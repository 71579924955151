import LeadershipCard from "../../components/cards/leadership"
import Footer from "../../components/layout/footer"
import Navbar from "../../components/layout/header"

import geoffreyFooter from "../../assets/images/footers/goeffrayFooter.svg"
import harryFooter from "../../assets/images/footers/harryFooter.svg"
import krikFooter from "../../assets/images/footers/krikFooter.svg"
import leanderFooter from "../../assets/images/footers/leanderFooter.svg"
import geoffreyPHD from "../../assets/images/leadersImage/geoffrey-PHD.svg"
import harryMBA from "../../assets/images/leadersImage/harry-MBA.svg"
import kirikMD from "../../assets/images/leadersImage/kirik-MD.svg"
import venNastePHD from "../../assets/images/leadersImage/venNaste-PHD.svg"
import howard from "../../assets/images/theme/howardKerman.svg"
import jason from "../../assets/images/theme/jason.svg"
import methew from "../../assets/images/theme/methewPutzi.svg"
import patrik from "../../assets/images/theme/patrik.svg"
import philipMD from "../../assets/images/theme/phillipKantof.svg"
import aminDirector from "../../assets/images/theme/amin.png"
import andrewDirector from "../../assets/images/theme/andrew.png"
import capFramr from "../../assets/images/theme/Frame.png"

const leadersTeam = [
  {
    img: { harryMBA },
    name: "Harry Stylli, PhD, MBA",
    designation: "Co-Founder and Executive Chairman",
    details:
      "<p className='leaderDetails'>Harry Stylli, PhD, is a founder of Immunis.AI and served as the CEO until April 2019. He currently serves as the Executive Chairman of the company. Harry is a seasoned executive and currently an investor and a serial entrepreneur across the healthcare and life science sectors. </p><p>Harry previously served as Founder and Executive Chairman at Progenity, Inc. Prior to this Dr. Stylli was President & Chief Executive Officer by Sequenom, Inc., President & Chief Executive Officer by Xencor, Inc., President & Chief Executive Officer by CovX Research LLC, President of Aurora Biosciences Corp., and President by PanVera LLC. He also served on the board at Molecular Insight Pharmaceuticals, Inc. </p><p>He received his undergraduate degree from the University of East London, an MBA from The Open University, and a doctorate degree from the University of London.</p>",
    footer: { img: harryFooter },
  },
  {
    img: { geoffreyPHD },
    name: "Geoffrey Erickson, PhD",
    designation: "Co-Founder and Senior VP, Corporate Development",
    details:
      "<p>Geoffrey Erickson serves as the Senior Vice President of Corporate Development for Immunis.AI and has been with the company since its founding. Prior to Immunis.AI, Geff was a Director of Business Development at Harvard University.</p><p>Prior to joining Harvard, Geff led other companies including CEO of Cytex Therapeutics, Inc., a company spun out of Duke University, and President and CEO of Humacyte, Inc., a company created from technology licensed out of Duke University and MIT.</p><p>Geff is also a former venture capitalist with Pappas Ventures, a firm focused on investments in the life science industry. Through Pappas Ventures, he was a founder of Colucid Pharmaceuticals that was acquired by Eli Lilly in 2017.</p><p>Geff received a BSE in engineering from Duke University, an MSE in biomedical engineering from Tulane University, and a PhD in biomedical engineering from Duke University.</p>",
    footer: { img: geoffreyFooter },
  },
  {
    img: { kirikMD },
    name: "Kirk Wojno, MD",
    designation: "Chief Medical Officer",
    details:
      "<p>Dr. Wojno is the chief medical officer at Immunis.AI where he works closely with clinicians and laboratory scientists to develop and transform modern molecular laboratory next generation sequencing patient data into relevant and clinically useful information that impacts patients’ lives. He is a board certified anatomic and clinical pathologist who trained at The Johns Hopkins hospital and holds subspecialty boards in clinical chemistry as well as having extensive experience running molecular diagnostic laboratories.</p><p>Throughout his 30 plus year career in pathology he has served as laboratory director, medical director, director of clinical research, chief medical officer and faculty member for many prestigious institutions including; Michigan Healthcare Professionals, Comprehensive Urology, Visiting Physicians Association, Pathnostics East, Progenity, Ameripath, St. Johns Ascension Health System, and the University of Michigan.</p><p>He has been extensively involved in research throughout his career and has published nearly 100 peer reviewed publications as well as numerous book chapters. He has served on scientific advisory boards and as a consultant to many of the top molecular diagnostic companies. He continues to educate the next generation of physicians and scientists and is a highly sought-after lecturer.</p><p>He is also an active member of the College of American Pathologists as well as numerous other societies where he advocates for appropriate and ethical use of, as well as fair access to, advanced molecular laboratory testing. He truly strives to use his gifts to make a difference in patients lives.</p>",
    footer: { img: krikFooter },
  },
  {
    img: { venNastePHD },
    name: "Leander Van Neste, PhD",
    designation: "Chief Scientific Officer",
    details:
      "<p>Leander Van Neste joined Immunis.AI as the Chief Scientific Officer in November 2019. He was previously Vice President of Scientific and Clinical Affairs at miDiagnostics and at MDxHealth. Leander brings years of experience in discovery, development, clinical validation, and launch of molecular diagnostic assays at private sector diagnostic companies.</p><p>Previously, Leander was an assistant professor at Maastricht University and a visiting professor at Ghent University where he taught R&D for diagnostics, molecular biology and epigenetics, and bioinformatics.</p><p>He holds an M.S. in bioengineering, specializing in cell and gene biotechnology, and a PhD in applied biological sciences from the Ghent University, in collaboration with Johns Hopkins University. Leander also holds a joint consultant role with Epify, a privately held epigenetics company.</p>",
    footer: { img: leanderFooter },
  },
]

const scientificBoardTeam = [
  {
    img: { philipMD },
    name: "Phillip Kantoff, MD",
    designation:
      "CEO and Co-Founder - Convergent Therapeutics Professor Emeritus, Harvard",
    details: "",
  },
  {
    img: { patrik },
    name: "Patrick M Hurley, MD",
    designation: "Comprehensive Urology MI",
    details: "",
  },

  {
    img: { methew },
    name: "Mathew Putzi, MD",
    designation: "Urology Austin TX",
    details: "",
  },

  {
    img: { howard },
    name: "Howard Korman, MD",
    designation: "Comprehensive Urolog MI",
    details: "",
  },
  {
    img: { jason },
    name: "Jason Hafron, MD",
    designation: "Michigan Institute of Urology MI",
    details: "",
  },
]

const directorsTeam = [
  {
    img: { harryMBA },
    name: "Harry Stylli, PhD, MBA",
    designation: "Co-Founder and Executive Chairman",
    details:
      "",
    footer: { img: harryFooter },
  },
  {
    img: { aminDirector },
    name: "Amin I. Kassis, PhD",
    designation: "Co-Founder & Director",
    details: "",
  },
  {
    img: { andrewDirector },
    name: "Andrew R. Midler",
    designation: "Director",
    details: "",
  },
]

const About = () => {
  return (
    <>
      <div id="who-we-are" className="DetailsDiv">
        <Navbar></Navbar>
        <div className="flex justify-start whoWeAreDiv">
          <div className="container">
            <div className="grid lg:grid-cols-2 gap-2 mt-32">
              {/* grid-1 */}
              <div>
                <div className="justify-start">
                  <span className="heading text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
                    WHO WE ARE
                  </span>
                </div>
                <div>
                  <div className="aboutContentDetails mb-5 mt-4">
                    <p className="p-2 paragraph mb-5">
                      Immunis.AI is a privately held immunogenomics company
                      committed to making a global impact by empowering patients
                      and their physicians with actionable information for
                      disease management.
                    </p>
                    <div className="flex items-center pb-5">
                      <h3 className="heading text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
                        01
                      </h3>
                      <p className="paragraph">
                        Our patented platform, originally created at Harvard Medical School, leverages the body’s own immune defense system, state of the art analytical methods, and AI / machine learning to provide unique real-time insights into a patient’s disease.
                      </p>
                    </div>
                    <div className="flex items-center pb-5">
                      <h3 className="heading text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
                        02
                      </h3>
                      <p className="paragraph">
                        The assays we develop enhance early-stage disease characterization and monitoring, to the point where biological disease editing allows escape from immune surveillance.
                      </p>
                    </div>
                    <div className="flex items-center pb-5">
                      <h3 className="heading text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
                        03
                      </h3>
                      <p className="paragraph">
                        Applying our proprietary AI algorithms, we compare gene expression patterns from two types of immune cells isolated from blood. This comparison, which essentially is the subtraction of gene expression in one cell from gene expression in another, normalizes and enhances the signal for a truly individualized assessment.
                      </p>
                    </div>
                    <div className="flex items-center pb-5">
                      <h3 className="heading text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
                        04
                      </h3>
                      <p className="paragraph">
                        We have developed a broad and robust Intellectual Property portfolio that protects our platform as well as each assay that we are developing.
                      </p>
                    </div>
                  </div>
                  <p className="p-2 md:mb-4 mb-2 paragraph">
                    Immunis.AI’s laboratory located in Royal Oak Michigan
                    follows the highest standards of clinical laboratory
                    testing. The laboratory is certified by the Clinical
                    Laboratory Improvement Amendments (CLIA) and accredited by
                    the College of American Pathologists (CAP).
                  </p>
                  <img src={capFramr} alt="" />
                </div>
              </div>

              {/* grid-2 */}

              <div className="bg-transparent"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="aboutContainer">
        <div id="leadership-team" className="container py-5">
          <div className="flex justify-start">
            <span className="leadershipHeading mt-5">
              Leadership Team
            </span>
          </div>

          <div className="grid gap-3 py-5 md:grid-cols-2 lg:grid-cols-4 sm:grid-cols-1">
            {leadersTeam.map((data: any, index) => (
              <div key={index}>
                <LeadershipCard
                  footer={data?.footer}
                  iconEnable={true}
                  image={data?.img}
                  name={data?.name}
                  designation={data?.designation}
                  details={data?.details}
                ></LeadershipCard>
              </div>
            ))}
          </div>

          <div id="clinical-advisory" className="flex justify-start mt-5">
            <span className="scientificBoardHeading">
              CLINICAL Advisory Board
            </span>
          </div>
          <div className="grid gap-3 py-5 md:grid-cols-2 lg:grid-cols-4 sm:grid-cols-1">
            {scientificBoardTeam.map((data: any, index) => (
              <div key={index}>
                <LeadershipCard
                  iconEnable={false}
                  image={data?.img}
                  name={data?.name}
                  designation={data?.designation}
                  details={data?.details}
                ></LeadershipCard>
              </div>
            ))}
          </div>

          <div id="board-of-directors" className="flex justify-start mt-5">
            <span className="scientificBoardHeading">
              DIRECTORS
            </span>
          </div>
          <div className="grid gap-3 py-5 md:grid-cols-2 lg:grid-cols-4 sm:grid-cols-1">
            {directorsTeam.map((data: any, index) => (
              <div key={index}>
                <LeadershipCard
                  iconEnable={false}
                  image={data?.img}
                  name={data?.name}
                  designation={data?.designation}
                  details={data?.details}
                  footer={data?.footer}
                ></LeadershipCard>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}
export default About
