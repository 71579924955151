import { Form, Formik } from "formik"
import { useEffect, useState } from "react"

import InformationCard from "../../components/cards"
import Footer from "../../components/layout/footer"
import Navbar from "../../components/layout/header"
import { InfoCardNews } from "../../constants/newsCards"
import { MixpanelTracking } from "../../services/Mixpanel"

import ParticipationInActiveSurveillancePatientsInternational from "../../assets/images/theme/ParticipationInActiveSurveillancePatientsInternational.jpg"
import BloodBasedImmune from "../../assets/images/theme/Blood-BasedImmune.png"
import liquidBiopsy from "../../assets/images/theme/liquid-biopsy.png"
import ChoosenByAWS from "../../assets/images/theme/ChoosenByAWS.jpg"
import AchievesDevelopmentMilestones from "../../assets/images/theme/AchievesDevelopmentMilestones.jpg"
import DiscoveryCancerRelatedSignals from "../../assets/images/theme/DiscoveryCancerRelatedSignals.jpg"
import AnnouncesAdditionalPatentsGrantedByUSPTO from "../../assets/images/theme/AnnouncesAdditionalPatentsGrantedByUSPTO.jpg"
import TwoPatentsGrantedByTheEuropeanPatentOffice from "../../assets/images/theme/TwoPatentsGrantedByTheEuropeanPatentOffice.jpg"
import PatentsGrantedByUSPTO from "../../assets/images/theme/PatentsGrantedByUSPTO.jpg"
import OncoCellMDxAppointsRenownedUrologicPathologistAsChiefMedicalOfficer from "../../assets/images/theme/OncoCellMDxAppointsRenownedUrologicPathologistAsChiefMedicalOfficer.jpg"
import LateBreakingPosterPresentationAtAACR2019 from "../../assets/images/theme/LateBreakingPosterPresentationAtAACR2019.jpg"

import forwardArrow from "../../assets/images/iconsVector/Arrows.svg"
import backArraow from "../../assets/images/iconsVector/backarrowNews-.svg"
import searchIcon from "../../assets/images/iconsVector/searchIcon.svg"

const NewsInformation = () => {
  const [search, setSearch] = useState("")

  /*   useEffect(() => {
  }, [search]) */

  /* search input filter articles */
  const filteredResults = InfoCardNews.filter((item) =>
    item.detail.toLowerCase().includes(search.toLocaleLowerCase())
  )

  function ChooseImage(image: string) {
    switch (image) {
      case "ParticipationInActiveSurveillancePatientsInternational":
        return ParticipationInActiveSurveillancePatientsInternational
      case "BloodBasedImmune":
        return BloodBasedImmune
      case "liquidBiopsy":
        return liquidBiopsy
      case "ChoosenByAWS":
        return ChoosenByAWS
      case "AchievesDevelopmentMilestones":
        return AchievesDevelopmentMilestones
      case "DiscoveryCancerRelatedSignals":
        return DiscoveryCancerRelatedSignals
      case "AnnouncesAdditionalPatentsGrantedByUSPTO":
        return AnnouncesAdditionalPatentsGrantedByUSPTO
      case "TwoPatentsGrantedByTheEuropeanPatentOffice":
        return TwoPatentsGrantedByTheEuropeanPatentOffice
      case "PatentsGrantedByUSPTO":
        return PatentsGrantedByUSPTO
      case "OncoCellMDxAppointsRenownedUrologicPathologistAsChiefMedicalOfficer":
        return OncoCellMDxAppointsRenownedUrologicPathologistAsChiefMedicalOfficer
      case "LateBreakingPosterPresentationAtAACR2019":
        return LateBreakingPosterPresentationAtAACR2019
    }
  }

  /* paginator */
  const [currentPage, setCurrentPage] = useState(1)
  const limitArticles = 6
  const totalPages = Math.ceil(filteredResults.length / limitArticles)

  const startIndex = (currentPage - 1) * limitArticles
  const finalIndex = currentPage * limitArticles
  const articlesShown = filteredResults.slice(startIndex, finalIndex)

  const previousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  return (
    <>
      <Navbar />
      <div className="pressReleaseContainer">
        <div className="mt-32">
          <div className="flex justify-between">
            <div className="my-4 lg:mb-0">
              <span className="heading2 text-transparent bg-clip-text bg-gradient-to-r from-[#378091] to-[#F2E854] font-Montserrat">
                News
              </span>
            </div>
          </div>

          <div className="searchArticleForm pb-2">
            <Formik
              initialValues={{ searchInput: "" }}
              onSubmit={(values) => {
                setSearch(values.searchInput)
              }}
            >
              {({ values, handleChange }) => (
                <Form>
                  <div className="flex justify-between w-full mt-4 formFeildControl">
                    <img className="p-3" src={searchIcon} />
                    <input
                      type="text"
                      id="searchInput"
                      name="searchInput"
                      placeholder="Search article"
                      className="w-full text-white bg-transparent inputFeild"
                      onChange={handleChange}
                      value={values.searchInput}
                    />
                    <div className="p-1">
                      <button type="submit" className="btn articleSearchButton">
                        <span className="m-3 articleButtonSearchText">
                          Search
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {articlesShown.length ? (
            <>
              <div className="grid mt-4 lg:grid-cols-3 lg:gap-3 md:grid-cols-2 md:gap-2 sm:grid-cols-1 sm:gap-1">
                {articlesShown.map((item, index) => (
                  <div className="mb-4 lg:mb-0" key={index}>
                    <InformationCard
                      dateAndReadingTime={item.date_time}
                      image={ChooseImage(item.image)}
                      detail={item.detail}
                      detailLong={item.detailLong}
                      article={item.url}
                      key={index}
                      onClick={ () => MixpanelTracking.getInstance().VisitArticle(item.detail) }
                    ></InformationCard>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className="w-full noMatches">
                <span className="title">No matches found</span>
                <span className="text">Try another article name.</span>
              </div>
            </>
          )}

          {/* <hr className="mb-10 text-white" /> */}
        </div>
        {articlesShown.length >= 1 && (
        <div className="flex justify-between align-items-center lg:mt-5 md:mt-3 sm:mt-3 d-sm-none d-md-flex d-lg-flex">
          <div className="resultsFound">{filteredResults.length} results found</div>
          <div className="paginationDetails">
            <img
              src={backArraow}
              onClick={previousPage}
              style={{ opacity: currentPage === 1 ? "0.5" : "1" }}
            />
            <span className="paginationNumber">{currentPage}</span>
            <img
              src={forwardArrow}
              onClick={nextPage}
              style={{ opacity: currentPage === totalPages ? "0.5" : "1" }}
            />
          </div>
        </div>
        )}
      </div>
      <Footer></Footer>
    </>
  )
}

export default NewsInformation
