
import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"

import { Popover } from "@headlessui/react"

import narrowUp from "../../../assets/images/iconsVector/ic_chevron.svg"
import narrowDown from "../../../assets/images/iconsVector/ic_chevron_down.svg"

interface NavLinkProps {
  text: string
  path?: string;
  dropdown?: boolean
  onClick?: () => void
  children?: any
}

const Component = (props: NavLinkProps) => {
  const location = useLocation()
  const currentPath = location.pathname
  const path = props.path || "#"
  
  if (props.dropdown) {
    return (
      <Popover onClick={props.onClick}>
        {({ open }) => (
          <>
            <Popover.Button className={`nav-link text-bold ${(open || currentPath.includes(path)) ? "selected" : ""}`}>
              {props.text}
              <img src={open ? narrowUp : narrowDown} />
            </Popover.Button>
            <Popover.Panel
              className={`popover-menu`}
            >
              {props.children}
            </Popover.Panel>
          </>
        )}
      </Popover>
    )
  }
  return (
    <Link
      to={path}
      className={`nav-link text-bold ${(currentPath.includes(path)) ? "selected" : ""}`}
      onClick={props.onClick}
    >
      {props.text}
    </Link>
  )
}

export default Component