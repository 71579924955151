import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Col, Row, FormGroup } from "reactstrap"
import { Field, Form, Formik } from "formik";
import jsonp from "jsonp";
import * as Yup from "yup";

import { ROUTES } from "../../../constants/routes"
import { MixpanelTracking } from "../../../services/Mixpanel";

import immunisAILogo from "../../../assets/images/backgroudImages/immunisAI-navbarContent.svg"
import warningIcon from "../../../assets/images/iconsVector/warningError.svg"

const initialValue = {
  FNAME: "",
  LNAME: "",
  EMAIL: "",
}

const ValidationSchema = Yup.object().shape({
  FNAME: Yup.string().required("FirstName is required"),
  LNAME: Yup.string().required("LastName is required"),
  EMAIL: Yup.string().required("Email is required")
});


const index = () => {
  const [isHomepage, setIsHomepage] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname;

  useEffect(() => {
    if (path === "/") {
      setIsHomepage(true)
    }
  }, []);

  const handleFormSubmit = (values: any, actions: any) => {
    const url = "https://immunis.us21.list-manage.com/subscribe/post-json?u=2b572b065e617a9e9f9b1f944&amp;id=970db65c77&amp;f_id=0025dfe6f0";
    setIsSubmitting(true);

    jsonp(`${url}&EMAIL=${values.EMAIL}&FNAME=${values.FNAME}&LNAME=${values.LNAME}&INQUIRY=${values.INQUIRY}&TITLE=${values.TITLE}&ORG=${values.ORG}&PHONE=${values.PHONE}`, { param: 'c' }, (_: any, data: any) => {
      const { msg, result } = data;

      if (result !== 'error') {
        actions.resetForm({ values: '' });
        MixpanelTracking.getInstance().SubscribedToNewsletter(values.EMAIL);
      }

      setIsSubmitting(false);
      alert(msg);
    });
  }

  return (
    <footer className="footer">
      <div className="footer-menu">
        <img src={immunisAILogo} alt="ImmunisAI logo" />
        <nav className="nav">
          <Link className={`${(path.includes(ROUTES.about)) ? "selected" : ""}`} to={ROUTES.about}>About</Link>
          <Link className={`${(path.includes(ROUTES.medicalNeed)) ? "selected" : ""}`} to={ROUTES.medicalNeed}>The Medical Need</Link>
          <Link className={`${(path.includes(ROUTES.immunisAISolution)) ? "selected" : ""}`} to={ROUTES.immunisAISolution}>Immunis.AI Solution</Link>
          <Link className={`${(path.includes(ROUTES.liquidBiopsy)) ? "selected" : ""}`} to={ROUTES.liquidBiopsy}>Liquid Biopsy Pipeline</Link>
          <Link className={`${(path.includes(ROUTES.news)) ? "selected" : ""}`} to={ROUTES.news}>News</Link>
          <Link className={`${(path.includes(ROUTES.contactUs)) ? "selected" : ""}`} to={ROUTES.contactUs}>Contact</Link>
        </nav>
      </div>
      {!isHomepage && (
        <>
          <div className="newsletter-subscription d-none d-lg-block">
            <p className="text-description">Join our newsletter:</p>
            <Formik
              initialValues={initialValue}
              onSubmit={(values, actions) => handleFormSubmit(values, actions)}
            >
              {({ errors }) => (
                <Form className="newsletter-footer mt-4">
                  <>
                    <Row className="gap-1 flex-nowrap">
                      <Col className="col-3">
                        <label htmlFor="FNAME">
                          First name<span className="requiredAstricks">*</span>
                        </label>
                        <Field
                          type="text"
                          id="FNAME"
                          name="FNAME"
                          placeholder="Enter First name"
                          className="text-white "
                        />
                        {errors.FNAME ? (
                          <div className="flex mb-2 mt-1 formError">
                            <img className="warning-img" src={warningIcon} />
                            <span className="ml-2">{errors.FNAME}</span>
                          </div>
                        ) : null}
                      </Col>
                      <Col className="col-3">
                        <label htmlFor="LNAME">
                          Last name <span className="requiredAstricks">*</span>
                        </label>
                        <Field
                          type="text"
                          id="LNAME"
                          name="LNAME"
                          placeholder="Enter Last name"
                          className="text-white "
                        />
                        {errors.LNAME ? (
                          <div className="flex mb-2 mt-1 formError">
                            <img className="warning-img" src={warningIcon} />
                            <span className="ml-2">{errors.LNAME}</span>
                          </div>
                        ) : null}
                      </Col>
                      <Col className="col-3">
                        <label htmlFor="EMAIL">
                          Email address<span className="requiredAstricks">*</span>
                        </label>
                        <div className="grid grid-cols-4 gap-3">
                          <div className="col-span-3">
                            <Field
                              type="text"
                              id="EMAIL"
                              name="EMAIL"
                              placeholder="Enter email Adress"
                              className="text-white "
                            />
                          </div>

                        </div>
                        {errors.EMAIL ? (
                          <div className="flex mb-2 mt-1 formError">
                            <img className="warning-img" src={warningIcon} />
                            <span className="ml-2">{errors.EMAIL}</span>
                          </div>
                        ) : null}
                      </Col>
                      <Col className="col-3 d-flex align-items-end">
                        <button
                          disabled={!errors || isSubmitting}
                          type="submit"
                          className="btn cta lg-btn"
                        >
                          Subscribe
                        </button>
                      </Col>
                    </Row>
                  </>
                </Form>
              )}
            </Formik>
          </div>
          <div className="join-to-newsletter-redirect d-block d-lg-none">
            <p>Want to subscribe to news? Click <Link className="link-text" to={`${ROUTES.home}#connect-with-us`}>here</Link></p>
          </div>
        </>
      )}
      <div>
        <div className="copy">© 2024. ImmunisAI. All rights reserved</div>
        <div className="legal">
          <Link className={`${(path.includes(ROUTES.terms)) ? "selected" : ""}`} to={ROUTES.terms}>Terms of Use</Link>
          <Link className={`${(path.includes(ROUTES.privacy)) ? "selected" : ""}`} to={ROUTES.privacy}>Privacy Policy</Link>
        </div>
      </div>
    </footer>
  )
}

export default index
