import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../constants/routes"

interface InformationCardProps {
  image: string | undefined
  dateAndReadingTime: string
  detail: string
  detailLong: string
  article: string
  onClick?: () => void
}

const InformationCard = (props: InformationCardProps) => {
  const navigate = useNavigate()
  const data = props

  return (
    <>
      <div
        className="overflow-hidden shadow-lg informationCard hover:cursor-pointer h-100"
        onClick={() => {navigate(props.article) , props.onClick && props.onClick()}}
      >
        <img
          className="w-full"
          src={data.image}
          alt="Sunset in the mountains"
        />
        <div className="cardDetailStyling">
          <div className="cardDateDetails">{data.dateAndReadingTime}</div>
          <p className="mt-3 details">{data.detail}</p>
          <p className="detailLong mt-3">
            {data.detailLong.length > 190
              ? data.detailLong.substring(0, 190) + "..."
              : data.detailLong}
          </p>
        </div>
      </div>
    </>
  )
}

export default InformationCard
