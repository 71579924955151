import { useEffect } from "react"
import { useLocation } from "react-router"

const ScrollWrapper = () => {
  const location = useLocation()
  const { hash } = location

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    if (hash) {
      const section = document.querySelector(hash)
      section?.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [hash])

  return null
}

export default ScrollWrapper
