import Footer from "../../../components/layout/footer"
import Navbar from "../../../components/layout/header"
import { ROUTES } from "../../../constants/routes"

import stckBackground from "../../../assets/images/backgroudImages/AchievesDevelopmentMilestones.jpg"

import ArticleStructure from "../../../components/articleStructure"

export default function index() {

  return (
    <>
      <Navbar></Navbar>

      <ArticleStructure
        location="ROYAL OAK, Michigan"
        date="September 29, 2021"
        title="Immunis.AI Achieves Development Milestones and Additional Financing"
        initialText=""
        backgroundImage={stckBackground}
        previousNews={ROUTES.article6}
        aboutEnabled={true} netxNews={ROUTES.article8}
      >
        <div className="pt-3">
          <p className="text">
            Immunis.AI, Inc., today announced the close of additional financing
            that brings its total raised to $40 million to date for the ongoing
            development of its proprietary Intelligentia immunogenomic platform.
            The additional funding comes on the heels of successful completion
            of key development milestones, including three pivotal studies of
            the Intelligentia platform. Funding will support further validation
            and commercialization of the company’s first molecular test in
            prostate cancer and will also be used to expand development of new
            tests in breast, colon, lung cancer and other cancers.
          </p>
        </div>
        <div className="mt-3">
          <p className="text">
            The Intelligentia liquid biopsy platform enables a highly sensitive
            method for the detection and grading of early-stage cancer and other
            diseases using a single blood sample. The platform is used to
            identify and interrogate novel disease-specific biomarkers as well
            as biomarkers indicative of the immune response to the disease.
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
            Immunis has developed a platform that harnesses the power of the
            immune system, achieving potent signal enrichment using proprietary
            methods and advanced machine learning. With the recently achieved
            major milestones, the company is poised to deliver on its core
            mission to improve early detection and patient outcomes.
            <p className="attribution text">
              Harry Stylli, Ph.D., Executive Chairperson of the Board at
              Immunis.AI
            </p>
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
            We are grateful for the continuing support of our investors, who
            share our excitement about the potential of our proprietary
            immunogenomic platform to transform patient care paradigms. We look
            forward to reporting results from our pivotal studies in prostate
            cancer and to delivering tests to better aid in the diagnosis and
            treatment of patients early, when there is the greatest opportunity
            for cure. helping them.
            <p className="attribution text">
              Christopher Thibodeau, President & CEO of Immunis.AI
            </p>
          </p>
        </div>
        <div className="mt-5 ">
          <p className="ml-2 text">
            The successful completion of milestones triggered the final tranche
            of new capital received over the last several months and positions
            the company to capitalize on the strong performance of the
            Intelligentia platform, to develop new pan cancer and disease
            surveillance tests, and to further advance commercial readiness of
            its lead test for the active surveillance of prostate cancer. The
            financing was led by Savitr Capital, LLC with participation from
            existing investors and management.
          </p>
        </div>
      </ArticleStructure>

      <Footer></Footer>
    </>
  )
}
