import { useNavigate } from "react-router-dom"

import { ROUTES } from "../../constants/routes"

import FaddedArrow from "../../assets/images/theme/greyaRightArrow.svg"
import RightArrow from "../../assets/images/theme/rightarrow.svg"
import WhiteArrow from "../../assets/images/theme/whiteLeftArrow.svg"

interface InformationArticleProps {
  location?: string
  date?: string
  title: string
  backgroundImage?: string
  initialText: string | JSX.Element | JSX.Element[]
  children: JSX.Element | JSX.Element[]
  previousNews: string
  netxNews: string
  aboutEnabled: boolean 
}

const ArticleStructure = (props: InformationArticleProps) => {
  const navigate = useNavigate()
  const navigateToNewsPage = () => {
    navigate(ROUTES.news)
  }


  return (
    <div className="mt-40">
      <div className="articlecontainer">
        <div className="container">
          <div className="articleContantCont">
            <div className="rightarrow">
              <ul className="p-0 mt-4">
                <li
                  className="p-0 hover:cursor-pointer"
                  onClick={() => {
                    navigateToNewsPage()
                  }}
                >
                  <img src={RightArrow} />
                  <p className="ml-3">Back to News</p>
                </li>
              </ul>
            </div>
            <div>
              <p className="py-5 dateAndLocation">
                {props.location}{!props.location || !props.date ? "" : " • "}{props.date}
              </p>
            </div>
            <div className="articleText">
              <h1 className="immunisHeading text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]">
                {props.title}
              </h1>
            </div>
            <div className="py-3 pb-4">
              <span className="immunistext"> {props.initialText}</span>
            </div>
          </div>
        </div>

        {props.backgroundImage && (
          <div className="flex pt-3 pb-5">
            <img className="w-full" src={props.backgroundImage} alt="" />
          </div>
        )}
      </div>

      <div className="articlecontainer">
        <div className="container pb-5">
          <div className="articleContantCont">
            {props.children}
            { props.aboutEnabled &&
            <div className="Aboutcard mt-5">
              <div className="AboutcardHeader">
                <h4 className="text-yellow">About Immunis.AI, Inc.</h4>
              </div>
              <div className="immunistext">
                IMMUNIS.AI is a privately held immunogenomics company with a
                patented liquid biopsy platform that offers unique insights into
                disease biology and individualized assessment. The Intelligentia
                platform combines the power of the immune system, RNAseq
                technology and Machine Learning (ML) for the development of
                disease-specific signatures. This proprietary method leverages
                the immune system’s surveillance apparatus to overcome the
                limitations of circulating tumor cells (CTCs) and cell free DNA
                (cfDNA). The platform improves detection of early-stage disease,
                at the point of immune-escape, when there is the greatest
                opportunity for cure. For more information, please visit our
                website: <a href="https://immunis.ai/">https://immunis.ai/..</a>
              </div>
            </div>}
            <div className="flex justify-between mt-5">
              <div className="rightarrow" onClick={() => navigate(props.previousNews)}>
                <ul className="p-0">
                  <li className="p-0">
                    <img src={FaddedArrow} />
                  </li>
                  <li className="mx-3">
                    <p className="previousArticle">Previous article</p>
                  </li>
                </ul>
              </div>
              <div className="rightarrow" onClick={() => navigate(props.netxNews)}>
                <ul className="p-0">
                  <li className="mx-3">
                    <p className="text-white previousArticle">Next article</p>
                  </li>
                  <li className="p-0">
                    <img src={WhiteArrow} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleStructure
