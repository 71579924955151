import Footer from "../../../components/layout/footer"
import Navbar from "../../../components/layout/header"
import { ROUTES } from "../../../constants/routes"

import stckBackground from "../../../assets/images/backgroudImages/TwoPatentsGrantedByTheEuropeanPatentOffice.jpg"

import ArticleStructure from "../../../components/articleStructure"

export default function index() {

  return (
    <>
      <Navbar></Navbar>

      <ArticleStructure
        location="ROYAL OAK, Michigan"
        date="July 14, 2020"
        title="Immunis.AI Announces Two Patents Granted by the European Patent Office"
        initialText="These additional patents further strengthen the company’s intellectual property position in novel methods and technologies for cancer detection and prognosis in Europe"
        previousNews={ROUTES.article6}
        aboutEnabled={true} netxNews={ROUTES.article8}
      >
        <div className="mt-3 w-100">
          <img className="w-100" src={stckBackground} />
          <p className="mt-2 text-gray text-center">
            European Patent Office (EPO). The image was taken from{" "}
            <a
              className="underlined"
              href="https://de.wikipedia.org/wiki/Europäische_Patentorganisation"
            >
              https://de.wikipedia.org/wiki/Europäische_Patentorganisation
            </a>
          </p>
        </div>
        <div className="pt-20">
          <p className="text">
          Immunis.AI, a privately held immunogenomics company with a patented liquid biopsy platform that offers unique insights into disease biology and individualized assessment, today announced that the European Patent Office (EPO) has granted two patents that protect the company’s innovations related to cancer detection and monitoring in the European market. 
          </p>
        </div>
        
        <div className="pt-4">
          <p className="text">
          <span className="text-yellow">Patents No. 2240781</span> and <span className="text-yellow">3358354</span> protect intellectual property related to the company’s novel methods of detecting signatures of disease, including the “subtractive method” for detecting signatures of disease or conditions in bodily fluids. These patents also cover novel methods for diagnosing disease, identifying a disease-specific signature, and in-vitro methods of diagnosing the presence of disease.
          </p>
        </div>
        <div className="mt-5">
          <p className="quote text">
          Immunis.AI holds one of the most robust patent portfolios in novel detection methods with the potential to overcome a limitation of circulating tumor cells (CTCs) and cell free DNA (cfDNA). The addition of these recent patents further strengthens our intellectual property position and underscores our commitment to innovation in advancing diagnostic development and delivery of high performing tests. These patents also provide protection 0f intellectual property related to diagnosis of multiple diseases.
            <p className="attribution text">
            Christopher Thibodeau, President and CEO, Immunis.AI
            </p>
          </p>
        </div>
        
      </ArticleStructure>

      <Footer></Footer>
    </>
  )
}
