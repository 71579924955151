import { useNavigate } from "react-router-dom"

import Footer from "../../../components/layout/footer"
import Navbar from "../../../components/layout/header"
import { ROUTES } from "../../../constants/routes"

import stckBackground from "../../../assets/images/backgroudImages/articleStockBackground.svg"
import BlueRibbion from "../../../assets/images/theme/blueribbion.svg"
import FaddedArrow from "../../../assets/images/theme/greyaRightArrow.svg"
import RightArrow from "../../../assets/images/theme/rightarrow.svg"
import WhiteArrow from "../../../assets/images/theme/whiteLeftArrow.svg"

import ArticleStructure from "../../../components/articleStructure"

export default function index() {
  const navigate = useNavigate()
  const navigateToNewsPage = () => {
    navigate(ROUTES.news)
  }

  return (
    <>
      <Navbar></Navbar>

      <ArticleStructure
        location="ROYAL OAK, Michigan"
        date="October 11, 2021"
        title="Immunis.AI Reports Results of a Prospective Study of its
        RNAseq-Based Liquid Biopsy in Early-Stage Prostate Cancer"
        initialText={
          <>
            <span className="evalutiontext">
              Evaluation of an RNAseq-based Immunogenomic Liquid Biopsy Approach
              in Early-Stage Prostate Cancer{" "}
            </span>
            , in the journal Cells.
          </>
        }
        backgroundImage={stckBackground}
        previousNews={ROUTES.article8}
        aboutEnabled={true} netxNews={ROUTES.article10}
      >
        <div className="pt-3">
          <p className="text">
            Study Findings Demonstrate the Company’s Proprietary Immunogenomic
            Platform Enhances Cancer Detection and Risk Stratification.
          </p>
        </div>
        <div className="mt-3">
          <p className="text">
            Study findings validate the potential of the company’s proprietary
            Intelligentia platform to detect early-stage cancer and to stratify
            risk in men considering active surveillance of prostate cancer. The
            results also illustrate the potential of the company’s proprietary
            immunogenomic platform to address screening, treatment decision
            making, and minimal residual disease detection.
          </p>
        </div>
        <div className="mt-3">
          <p className="text">
            This is the first of three large, prospective studies completed and
            designed to demonstrate the power of the company’s proprietary
            Intelligentia platform for early-stage cancer detection and the
            assessment of disease aggressiveness.{" "}
          </p>
        </div>
        <div>
          <h1 className="subtitle">Key Findings:</h1>
          <div className="mt-3">
            <p className="text">
              In this prospective study, the primary objective was to develop
              and validate a model for the identification of clinically
              significant prostate cancer. Peripheral blood samples were
              collected from 1,018 previously undiagnosed men undergoing
              prostate biopsy and split chronologically into independent
              training (n=713) and validation (n=305) sets. Whole transcriptome
              RNA sequencing was performed on isolated phagocytic CD14+
              monocytes and non-phagocytic CD2+ lymphocytes and their gene
              expression levels were used to develop a predictive model that
              correlates to adverse pathologic features in early stage,
              clinically localized prostate cancer.
            </p>
          </div>
          <div className="keyFindingStep">
            <ul>
              <li className="mt-3 ">
                <span className="ml-2 text">
                  The immunotranscriptomic model, emphasizing both the tumor
                  phagocytosis mechanism and the anti-tumor immune response,
                  combined with clinical risk parameters, yielded an AUC (area
                  under curve) of 0.83 on the independent validation set
                  (n=305).
                </span>
              </li>
              <li className="mt-3 ">
                <span className="ml-2 text">
                  The immunotranscriptomic model, emphasizing both the tumor
                  phagocytosis mechanism and the anti-tumor immune response,
                  combined with clinical risk parameters, yielded an AUC (area
                  under curve) of 0.83 on the independent validation set
                  (n=305).
                </span>
              </li>
              <li className="mt-3">
                <span className="ml-2 text">
                  The immunotranscriptomic model, emphasizing both the tumor
                  phagocytosis mechanism and the anti-tumor immune response,
                  combined with clinical risk parameters, yielded an AUC (area
                  under curve) of 0.83 on the independent validation set
                  (n=305).
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-3">
          <img className="blueRibbonImage" src={BlueRibbion} alt="" />
        </div>
        <div className="mt-20">
          <p className="text">
            These patents describe aspects of “subtractive method,” directed to
            detection, diagnosis and prognosis of prostate cancer, as well as
            head & neck cancer, by determining the difference between the levels
            of gene expression of certain sets of markers from the subject’s
            phagocytic and non-phagocytic cells. Taken together, these patents,
            along with other intellectual property of Immunis.AI, demonstrate
            that the subtractive method is patentable and has been patented by
            Immunis.AI.
          </p>
        </div>

        {/* <div className='positionBox'>    
         <div className='flex justify-center mt-20'>
        <span className='positiontext'>“Immunis.AI was one of the first companies to patent the subtractive method, positioning us to offer patients minimally-invasive cancer detection when there is the best opportunity for cure.<span className='text-transparent bg-clip-text bg-gradient-to-r from-[#378091]  to-[#F2E854]'> We hold one of the most robust patent portfolios </span>in novel detection methods with the potential to overcome a limitation of circulating tumor cells (CTCs) and cell free DNA (cfDNA),”</span>       
        </div>
        </div>
        <div className='mt-3'>
        <p className='text positiontextmargin'>— Christopher Thibodeau, President and CEO, Immunis.AI</p>
       </div> */}

        <div className="mt-5">
          <p className="text">
            The addition of these recent patents further strengthens our
            intellectual property position and underscores our commitment to
            innovation in advancing diagnostic development and delivery of high
            performing tests.
          </p>
        </div>
      </ArticleStructure>
      <Footer></Footer>
    </>
  )
}
