import mixpanel from 'mixpanel-browser'

export class MixpanelTracking {
  private static _instance: MixpanelTracking;

  public static getInstance(): MixpanelTracking {
    if (MixpanelTracking._instance == null) {
      return (MixpanelTracking._instance = new MixpanelTracking());
    }

    return this._instance;
  }

  public constructor() {
    if (MixpanelTracking._instance) {
      throw new Error(
        "Error: Instance creatin of MixpanelTracking is not allowed. Use Mixpanel.getInstance instead."
      );
    }

    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN_ID || "", {
      debug: false,
      ignore_dnt: true,
    });
  }

  public track(name: string, data: object = {}) {
    mixpanel.track(name, data);
  }

  public HomePage() {
    this.track("Home-page");
  }

  public ClickEvent(target: string) {
    this.track("Click-event", {
      target: target,
    });
  }

  public VisitSection(target: string, via: string) {
    this.track("Section-visited", {
      target: target,
      via: via,
    });
  }

  public VisitArticle(article: string) {
    this.track("Article-visited", {
      article: article,
    });
  }

  public FormSent() {
    this.track("Form-Sent")
  }

  public SubscribedToNewsletter(email: string) {
    this.track("Subscribed-to-newsletter",{
      email: email
    })
  }

  public TermsOfUse() {
    this.track("Terms-of-use")
  }

  public PrivacyPolicy() {
    this.track("Privacy-policy")
  }
}